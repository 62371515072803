import { AxiosPromise } from "axios"
import { http } from "src/utils/http"
import mrcTypes from 'src/custom/mrc/types/mrcTypes'
import {IResponse} from "mam-core-react/dist/modules/http"

namespace mrcApi {
    export namespace exhibitionApi{
        //获取服务包
        export const getServicePackages = (): AxiosPromise<IResponse<mrcTypes.exhibitionTypes.IQueryRes>> => {
            return http.get<IResponse<mrcTypes.exhibitionTypes.IQueryRes>>(`/api/sbsom/servicePackage/servicePackages`)
        }
        //依据key查询客户信息
        export const getCustomer = (key: string): AxiosPromise<IResponse<mrcTypes.exhibitionTypes.ICustomer>> => {
            return http.get<IResponse<mrcTypes.exhibitionTypes.ICustomer>>(`/api/sbsom/customer/customerbykey?key=${key}`)
        }
        //当前登录用户进行绑定key
        export const bindKey = (key: string): AxiosPromise<IResponse<any>> => {
            return http.post<IResponse<any>>(`/api/sbsom/config`,{key})
        }
        //获取key，检查当前登录用户是否已绑定key，有key则已绑定
        export const checkBindKey = (): AxiosPromise<IResponse<any>> => {
            return http.get<IResponse<any>>(`/api/sbsom/config`)
        }
    }
}

export default mrcApi
