import { http } from "../utils/http";
import { AxiosPromise } from "axios";
import uploadTypes from "../types/uploadTypes";
import {IResponse} from "mam-core-react/dist/modules/http";

namespace uploadApi {
    // 请求各种类型对应的元数据
    export const getMetadataField = (param: uploadTypes.IMetadataFieldReq): AxiosPromise<IResponse<uploadTypes.IMetadataFieldRes>> => {
        return http.post<IResponse<uploadTypes.IMetadataFieldRes>>('/upload/get-all-fields-by-source', param)
    }

    export const getUnfinishedTask = (param: uploadTypes.IUnfinishedTaskReq): AxiosPromise<IResponse<uploadTypes.IUnfinishedTaskRes[]>> => {
        return http.get<IResponse<uploadTypes.IUnfinishedTaskRes[]>>('/upload/get-unfinished-task?relationId=' + param.relationId + '&relationContentType=' + param.relationContentType + '&targetType=' + param.targetType);
    }
    // 检测客户端是否存在
    export const checkClient = (baseUrl: string): any => {
        return http.get(baseUrl + 'request/getuploadfiles?user_token=&filetype=all');
    }

    export const getUploadConfig = (): AxiosPromise<IResponse<uploadTypes.IUpoladConfig>> => {
        return http.get<IResponse<uploadTypes.IUpoladConfig>>('/config/get-upload-config')
    }
}
export default uploadApi;
