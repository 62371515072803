import { BaseStore } from 'mam-core-react';
import { Stores } from 'src/stores';
import { observable, action, runInAction } from 'mobx';
import IEntityStore from '../../iEntityStore';
import { message } from 'antd';
import _ from 'lodash'
import FacetSearchStore from './facetSearchStore';
import iEntityApi from 'src/apis/iEntityApi';
import iEntityTypes from 'src/types/iEntityTypes'

export default class IntelligentTimelineStore extends BaseStore<Stores> {
    @observable
    public footballDatas: iEntityTypes.IFootballData[] = []
    @observable
    public varietyShowDatas: iEntityTypes.IVarietyShowData[] = []
    @observable
    public tvShowDatas?: any
    @observable
    public filterStage: string = '全部';
    @observable
    public filterEvent: string = '全部';
    @observable
    public faceList: iEntityTypes.IFootballDataAthletes[];
    @observable
    public filterFace?: string;
    @observable
    public topTypeSwitch: string = 'timeline';
    @observable
    public selectedVarietyItemId?: string;
    @observable
    public isReqIntelligentData: boolean = true;
    public facetSearchStore: FacetSearchStore;
    public parentStore: IEntityStore;

    public constructor(stores: Stores, parentStore: IEntityStore) {
        super(stores)
        this.parentStore = parentStore;
        this.facetSearchStore = new FacetSearchStore(stores, this);
    }

    @action
    public getIntelligentData() {
        this.isReqIntelligentData = true;
        const allPromise = [];
        //足球
        allPromise.push(new Promise((resolve, reject)=>{
            iEntityApi.getMetadataObject(this.parentStore.params.contentId, 'model_sobey_smart_football').then(res => {
                runInAction(() => {
                    if (res.data.data && res.data.data.length > 0) {
                        let leftTeam: string | undefined;
                        let rightTeam: string | undefined;
                        res.data.data[0].metadata.forEach((item: iEntityTypes.IFootballData) => {
                            if (item.face.length > 0) {
                                if (!leftTeam) {
                                    leftTeam = item.team;
                                    item.side = 'left';
                                }
                                else if (leftTeam === item.team) {
                                    item.side = 'left';
                                }
                                else if (!rightTeam) {
                                    rightTeam = item.team;
                                    item.side = 'right';
                                }
                                else if (rightTeam === item.team) {
                                    item.side = 'right';
                                }
                            }
                        })
                        this.footballDatas = res.data.data[0].metadata;
                        this.getFaceList(this.footballDatas);
                    }
                })
            }, res => {
                if (res.data.error && res.data.error.title) {
                    message.error(res.data.error.title);
                }
            }).finally(()=>{
                resolve();
            });
        }))

        //综艺
        allPromise.push(new Promise((resolve, reject)=>{
            iEntityApi.getMetadataObject(this.parentStore.params.contentId, 'model_sobey_smart_partytimeline').then(res => {
                runInAction(() => {
                    if (res.data.data && res.data.data.length > 0) {
                        this.varietyShowDatas = res.data.data[0].metadata;
                    }
                })
            }, res => {
                if (res.data.error && res.data.error.title) {
                    message.error(res.data.error.title);
                }
            }).finally(()=>{
                resolve();
            });
        }))

        //电视剧
        allPromise.push(new Promise((resolve, reject)=>{
            iEntityApi.getMetadataObject(this.parentStore.params.contentId, 'model_sobey_smart_tv').then(res => {
                runInAction(() => {
                    if (res.data.data && res.data.data.length > 0
                        && res.data.data[0].metadata && res.data.data[0].metadata.length > 0) {
                        this.tvShowDatas = res.data.data[0].metadata[0];
                    }else{
                        this.tvShowDatas = [];
                    }
                })
            }, res => {
                if (res.data.error && res.data.error.title) {
                    message.error(res.data.error.title);
                }
            }).finally(()=>{
                resolve();
            });
        }))
        Promise.all(allPromise).finally(()=>{
            this.isReqIntelligentData = false;
        })
    }

    @action
    public getFaceList(datas: iEntityTypes.IFootballData[]) {
        let fList: iEntityTypes.IFootballDataAthletes[] = []
        datas.forEach((item) => {
            if (item.face) {
                fList = fList.concat(item.face)
            }
        })
        fList = _.uniqBy(fList, 'name');
        this.faceList = fList;
    }

    @action
    public setFilterStage(val: string) {
        this.filterStage = val;
    }

    @action
    public setFilterEvent(val: string) {
        this.filterEvent = val;
    }

    @action
    public topFace(face: string) {
        let faceItem = _.find(this.faceList, { name: face });
        if (faceItem) {
            _.remove(this.faceList, (item) => {
                return item === faceItem;
            })
            this.faceList.unshift(faceItem)
        }
    }

    @action
    public setFilterFace(face?: string) {
        this.filterFace = face;
    }

    @action
    public setTopTypeSwitch(type: string) {
        this.topTypeSwitch = type;
    }

    @action
    public setSelectedVarietyItemId(guid?: string) {
        this.selectedVarietyItemId = guid;
    }
}