import ExtendStore from "src/stores/base/extendStore"
import { action, observable, runInAction } from "mobx"
import { Stores } from "src/stores/index"
import _ from 'lodash'
import { message } from 'antd'
import mrcApi from 'src/custom/mrc/apis/mrcApi'
import mrcTypes from 'src/custom/mrc/types/mrcTypes'
import MrcStore from '../mrcStore'

export default class ExhibitionStore extends ExtendStore {
    @observable
    public isBinded: boolean = true
    @observable
    public queryRes: mrcTypes.exhibitionTypes.IQueryRes

    constructor(stores: Stores, mrcStore: MrcStore) {
        super(stores)
    }

    /* 获取服务包 */
    @action
    public getServicePackages = (): Promise<any> => {
        return new Promise((resolve, reject) => {
            mrcApi.exhibitionApi.getServicePackages().then((res) => {
                runInAction(() => {
                    if (res.data.success && res.data.data) {
                        this.queryRes = res.data.data
                        resolve(true)
                    }
                })
            }, (res) => {
                if (res.data.error && res.data.error.title){
                    message.error(res.data.error.title)
                }
                reject()
            })
        })
    }

    /* 依据key查询客户信息 */
    @action
    public getCustomer = (key: string): Promise<any> => {
        return new Promise((resolve, reject) => {
            mrcApi.exhibitionApi.getCustomer(key).then((res) => {
                runInAction(() => {
                    if (res.data.success) {
                        resolve(res.data.data)
                    }
                })
            }, (res) => {
                if (res.data.error && res.data.error.title){
                    message.error(res.data.error.title)
                }
                reject()
            })
        })
    }

    /* 当前登录用户进行绑定key */
    @action
    public bindKey = (key: string): Promise<any> => {
        return new Promise((resolve, reject) => {
            mrcApi.exhibitionApi.bindKey(key).then((res) => {
                runInAction(() => {
                    if (res.data.success) {
                        resolve(true)
                    }
                })
            }, (res) => {
                if (res.data.error && res.data.error.title){
                    message.error(res.data.error.title)
                }
                reject()
            })
        })
    }

    /* 检查当前登录用户是否已绑定key */
    @action
    public checkBindKey = (): Promise<any> => {
        return new Promise((resolve, reject) => {
            mrcApi.exhibitionApi.checkBindKey().then((res) => {
                runInAction(() => {
                    if (res.data.success) {
                        this.isBinded = res.data.data ? true : false
                        resolve(true)
                    }
                })
            }, (res) => {
                if (res.data.error && res.data.error.title){
                    message.error(res.data.error.title)
                }
                reject()
            })
        })
    }
    
}
