import { Stores } from 'src/stores';
import ExtendStore from 'src/stores/base/extendStore';
import IIndexStore from '../iIndexStore';
import { action, runInAction, observable } from 'mobx';
import iIndexApi from 'src/apis/iIndexApi';
import { message } from 'antd';
import _ from 'lodash'
import iIndexTypes from 'src/types/iIndexTypes';
import iSearchTypes from 'src/types/iSearchTypes';
import { commonUtil } from 'mam-common-utils';

export default class ColumnListStore extends ExtendStore {
    /** 通用列表 */
    @observable
    public listRes?: iIndexTypes.secList.IColumnListRes;
    @observable
    public isReqList: boolean = false;
    @observable
    public keyword: string = '';
    @observable
    public selectedConditions?: iSearchTypes.ISearchCondition[] = []
    private pageSize: number = 30;
    private iIndexStore: IIndexStore

    public constructor(stores: Stores, iIndexStore: IIndexStore) {
        super(stores);
        this.iIndexStore = iIndexStore;
        this.initSearchFilterStore(stores);
    }

    @action
    public getList(page?: number) {
        this.listRes = undefined;
        this.isReqList = true;
        let params: iIndexTypes.secList.IColumnListReq = {
            columnName: this.keyword,
            conditions: [],
            pageIndex: page || 1,
            pageSize: this.pageSize
        }
        if (this.selectedConditions) {
            this.selectedConditions.forEach((condition) => {
                if (condition.items) {
                    condition.items.forEach((n) => {
                        let item = _.find(params.conditions, { field: n.field });
                        if (item == null) {
                            params.conditions.push(n);
                        } else {
                            if (_.isArray(item.value)) {
                                if (!_.includes(item.value, n.value)) {
                                    item.value.push(n.value as string);
                                }
                            } else {
                                if (item.value !== n.value) {
                                    item.value = [item.value, n.value as string];
                                }
                            }
                        }
                    });
                } else {
                    if (condition.field && condition.value) {
                        params.conditions.push({ field: condition.field, value: condition.value })
                    }
                }
            });
        }
        iIndexApi.getColumnList(params).then(res => {
            runInAction(() => {
                if (res.data.data) {
                    this.listRes = res.data.data;
                    if (this.listRes.aggrs) {
                        this.initFacets(this.listRes.aggrs);
                    }
                    let codes: string[] = [];
                    if (this.listRes.data) {
                        this.listRes.data.forEach((item) => {
                            codes.push(item.columnCode);
                        })
                    }
                    this.getItemsDetails(codes);
                }
            })
        }, res => {
            if (res.data.error && res.data.error.title) {
                message.error(res.data.error.title);
            }
            runInAction(() => {
                this.isReqList = false;
            });
        });
    }

    @action
    public getItemsDetails(codes: string[]) {
        iIndexApi.getColumnListDetail(codes).then(res => {
            runInAction(() => {
                if (res.data.data) {
                    res.data.data.forEach((item) => {
                        if (this.listRes) {
                            let listItem = _.find(this.listRes.data, { columnCode: item.name });
                            if (listItem) {
                                listItem.detailInfo = item;
                            }
                        }
                    })
                    if (this.listRes) {
                        let arr: iIndexTypes.secList.IColumnListItem[] = _.orderBy(commonUtil.copyObj(this.listRes.data), (m) => m.detailInfo?.typeOverview[0].count, ['desc'])
                        let newArr: iIndexTypes.secList.IColumnListItem[] = []
                        let otherArr:iIndexTypes.secList.IColumnListItem[] = []
                        arr.forEach(m=> {
                            if (!m.detailInfo) {
                                otherArr.push(m)
                            } else {
                                newArr.push(m)
                            }
                        })
                        this.listRes.data = newArr.concat(otherArr)
                    }
                }
                this.isReqList = false;
            })
        }, res => {
            if (res.data.error && res.data.error.title) {
                message.error(res.data.error.title);
            }
            runInAction(() => {
                this.isReqList = false;
            });
        });
    }

    @action
    public setKeyword(keyword: string) {
        this.keyword = keyword;
    }

    @action
    public setSelectedConditions(conditions?: iSearchTypes.ISearchCondition[]) {
        this.selectedConditions = conditions;
    }

    /** 初始化层面数据 */
    private initFacets(facets: iSearchTypes.IFacets[]) {
        if (facets) {
            facets.forEach(item => {
                if (item.facetFieldName === 'createDate_') {
                    item.startDate = '';
                    item.endDate = '';
                }
                item.showMore = false;
                if (item.facetValue) {
                    item.facetValue.forEach((o: iSearchTypes.IFacetsFacetValue) => {
                        o.isShow = true;
                        o.isSelected = false;
                    });
                }
                item.checkboxIsOpen = false;
            });
        }
    }
}