import { AxiosPromise } from 'axios';
import { IResponse, http } from 'mam-core-react/dist/modules/http';
import entityTypes from "src/types/entityTypes";
import contentMgTypes from 'src/types/contentMgTypes';
import ccTypes from "src/types/ccTypes"
import { stores } from 'src/stores';
import IFormItem from "mam-metadata-react/dist/types/IFormItem";

namespace contentMgApi {
    export namespace allAlbums {
        export const searchAlbums = (params: contentMgTypes.allAlbums.ISearchAlbumsReq): AxiosPromise<IResponse<contentMgTypes.allAlbums.ISearchAlbumsRes>> => {
            return http.post<IResponse<contentMgTypes.allAlbums.ISearchAlbumsRes>>(`/es/search/album`, params)
        }
        //专辑推送
        export const pushAlbums = (contentIds: string[]): AxiosPromise<IResponse<any>> => {
            return http.post<IResponse<any>>(`/aialbum/push`, contentIds)
        }
        //专辑取消推送
        export const pushAlbumsCancel = (contentIds: string[]): AxiosPromise<IResponse<any>> => {
            return http.post<IResponse<any>>(`/aialbum/push/cancel`, contentIds)
        }
    }

    export namespace catalogTask {
        //获取编目配置
        export const getCatalogConfig = (): AxiosPromise<IResponse<contentMgTypes.catalogTask.ICatalogConfig>> => {
            return http.get<IResponse<contentMgTypes.catalogTask.ICatalogConfig>>(`/catalog/config`)
        }
        //我的编目任务: taskState: 10编目中 20已提交 30被打回
        export const queryCatalogTask = (req: contentMgTypes.catalogTask.IQueryReq): AxiosPromise<IResponse<contentMgTypes.catalogTask.IQueryRes>> => {
            return http.get<IResponse<contentMgTypes.catalogTask.IQueryRes>>(`/catalogue/v2/mytask?taskState=${req.taskState}&pageIndex=${req.pageIndex}&pageSize=${req.pageSize}&sort=${req.sort}&order=${req.order}&keyword=${encodeURIComponent(req.keyword)}&smartstate=${req.smartstate}`)
        }
        //待认领的编目任务
        export const queryWaitedCatalogTask = (req: contentMgTypes.catalogTask.IQueryReq): AxiosPromise<IResponse<contentMgTypes.catalogTask.IQueryRes>> => {
            return http.get<IResponse<contentMgTypes.catalogTask.IQueryRes>>(`/catalogue/v2/task/unclaim/catalog?pageIndex=${req.pageIndex}&pageSize=${req.pageSize}&sort=${req.sort}&order=${req.order}&keyword=${encodeURIComponent(req.keyword)}&smartstate=${req.smartstate}`)
        }
        //创建我的编目任务：认领
        export const createMyTask = (taskIds: number[]): AxiosPromise<IResponse<any[]>> => {
            return http.post<IResponse<any[]>>(`/catalogue/v2/mytask`, taskIds)
        }
        //取消认领我的编目任务
        export const cancelMyCatalogTask = (taskIds: number[]): AxiosPromise<IResponse<any[]>> => {
            return http.post<IResponse<any[]>>(`/catalogue/v2/mytask/cancel`, taskIds)
        }

        //审核任务： auditLevel： 1、2、3  taskState: 10审核中 20已审核 30被打回
        export const queryAuditTask = (req: contentMgTypes.catalogTask.IQueryReq): AxiosPromise<IResponse<contentMgTypes.catalogTask.IQueryRes>> => {
            return http.get<IResponse<contentMgTypes.catalogTask.IQueryRes>>(`/catalogue/v2/audit/task?taskState=${req.taskState}&auditLevel=${req.auditLevel}&pageIndex=${req.pageIndex}&pageSize=${req.pageSize}&sort=${req.sort}&order=${req.order}&keyword=${encodeURIComponent(req.keyword)}&smartstate=${req.smartstate}`)
        }
        //待认领的审核任务
        export const queryWaitedAuditTask = (req: contentMgTypes.catalogTask.IQueryReq): AxiosPromise<IResponse<contentMgTypes.catalogTask.IQueryRes>> => {
            return http.get<IResponse<contentMgTypes.catalogTask.IQueryRes>>(`/catalogue/v2/task/unclaim/audit?auditLevel=${req.auditLevel}&pageIndex=${req.pageIndex}&pageSize=${req.pageSize}&sort=${req.sort}&order=${req.order}&keyword=${encodeURIComponent(req.keyword)}&smartstate=${req.smartstate}`)
        }
        //创建审核任务: 认领
        export const createAuditTask = (taskIds: number[], auditLevel: number): AxiosPromise<IResponse<any[]>> => {
            return http.post<IResponse<any[]>>(`/catalogue/v2/audit/task`, { taskIds, auditLevel })
        }
        //取消审核任务
        export const cancelAuditTask = (taskids: number[], auditLevel: number): AxiosPromise<IResponse<any[]>> => {
            return http.post<IResponse<any[]>>(`/catalogue/v2/audit/cancel`, { taskids, auditLevel })
        }
        //全部任务中： taskType： ''全部 1进行中、2失败、3成功、4取消，查全部则传taskType为空   //全部任务中：未分配 unAssign: true
        export const queryTraceTask = (req: contentMgTypes.catalogTask.IQueryReq): AxiosPromise<IResponse<contentMgTypes.catalogTask.IQueryRes>> => {
            return http.get<IResponse<contentMgTypes.catalogTask.IQueryRes>>(`/catalogue/v2/task/trace?pageIndex=${req.pageIndex}&pageSize=${req.pageSize}&sort=${req.sort}&order=${req.order}&keyword=${encodeURIComponent(req.keyword)}&smartstate=${req.smartstate}&taskType=${req.taskType}` + (req.unAssign ? `&unAssign=${req.unAssign}` : ``) + `&column=${req.column}&channel=${req.channel}&contentSource=${req.contentSource}`)
        }
        //分配编目任务
        export const assignCatalogTask = (req: contentMgTypes.catalogTask.IAssignReq): AxiosPromise<IResponse<any[]>> => {
            return http.post<IResponse<any[]>>(`/catalogue/v2/manager/assign/catalog`, req)
        }
        //分配审核任务
        export const assignAuditTask = (req: contentMgTypes.catalogTask.IAssignReq): AxiosPromise<IResponse<any[]>> => {
            return http.post<IResponse<any[]>>(`/catalogue/v2/manager/assign/audit`, req)
        }
        //给任务分配用户： 分配编目人员、一审、二审、三审人员
        export const assignUsers = (req: contentMgTypes.catalogTask.IAssignUsersReq): AxiosPromise<IResponse<any[]>> => {
            return http.post<IResponse<any[]>>(`/catalogue/v2/manager/assign`, req)
        }
        //取消审校
        export const cancelReview = (taskIds: number[]): AxiosPromise<IResponse<any[]>> => {
            return http.post<IResponse<any[]>>(`/catalogue`, taskIds)
        }
        //取消分配
        export const cancelAssign = (taskIds: number[]): AxiosPromise<IResponse<any[]>> => {
            return http.post<IResponse<any[]>>(`/catalogue/v2/manager/cancel/assigned`, taskIds)
        }
        //取消编目
        export const cancelCatalog = (taskIds: number[]): AxiosPromise<IResponse<any[]>> => {
            return http.post<IResponse<any[]>>(`/catalogue/v2/manager/cancel/task`, taskIds)
        }
        //重做任务
        export const auditRework = (taskIds: number[]): AxiosPromise<IResponse<any[]>> => {
            return http.post<IResponse<any[]>>(`/catalogue/v2/manager/redo`, taskIds)
        }

        //获取用户
        export const getUsers = (): AxiosPromise<IResponse<contentMgTypes.catalogTask.IUserRes>> => {
            return http.get<IResponse<contentMgTypes.catalogTask.IUserRes>>(`/catalogue/v2/users`)
        }

        //获取全部栏目、频道、来源
        export const getFieldOptions = (fieldName: string): AxiosPromise<IResponse<contentMgTypes.catalogTask.IFieldOptionsItem[]>> => {
            return http.get<IResponse<contentMgTypes.catalogTask.IFieldOptionsItem[]>>(`/catalogue/v2/task/trace/field/distinct?fieldName=${fieldName}`)
        }

    }
    export namespace catalogStatistic{
        //获取编目量yl
        export const getFinishNum = (): AxiosPromise<IResponse<any>> => {
            return http.get<IResponse<any>>(`/catalogue/v2/statistic/finish/num`)
        }

        //获取审核量yl
        export const getAuditNum = (): AxiosPromise<IResponse<any>> => {
            return http.get<IResponse<any>>(`/catalogue/v2/statistic/audit/num`)
        }

        //获取编目数量分析yl
        export const getFinishNumTrend = (type:string): AxiosPromise<IResponse<any>> => {
            return http.get<IResponse<any>>(`/catalogue/v2/statistic/finish/num/trend?dateType=${type}`)
        }

        //获取用户编目排名yl
        export const getUserFinishNum = (isDelay:boolean): AxiosPromise<IResponse<any>> => {
            return http.get<IResponse<any>>(`/catalogue/v2/statistic/user/finish/num?isDelay=${isDelay}`)
        }

        //获取审核数量分析yl
        export const getAuditNumTrend = (type:string): AxiosPromise<IResponse<any>> => {
            return http.get<IResponse<any>>(`/catalogue/v2/statistic/audit/num/trend?dateType=${type}`)
        }

        //获取用户审核排名yl
        export const getUserAuditNum = (isDelay:boolean): AxiosPromise<IResponse<any>> => {
            return http.get<IResponse<any>>(`/catalogue/v2/statistic/user/audit/num?isDelay=${isDelay}`)
        }

        //获取审核合格率分析yl
        export const getPassRate = (type:string): AxiosPromise<IResponse<any>> => {
            return http.get<IResponse<any>>(`/catalogue/v2/statistic/pass-rate?dateType=${type}`)
        }

        //获取用户审核合格率排名yl
        export const getUserPassRate = (): AxiosPromise<IResponse<any>> => {
            return http.get<IResponse<any>>(`/catalogue/v2/statistic/user/pass-rate`)
        }

    }

    export namespace bulletinManage {
        //公告类型
        export const getBulletinType = (): AxiosPromise<IResponse<contentMgTypes.bulletin.INav[]>> => {
            return http.get<IResponse<contentMgTypes.bulletin.INav[]>>('/announcement/config')
        }
        //新增公告类型
        export const addBulletinType = (value: string): AxiosPromise<IResponse<contentMgTypes.bulletin.INav>> => {
            return http.post<IResponse<contentMgTypes.bulletin.INav>>('/announcement/config', { name: value })
        }
        //删除公告类型
        export const deleteBulletinType = (code: string): AxiosPromise<IResponse<any>> => {
            return http.delete(`/announcement/config?code=${code}`)
        }
        //跟新公告类型
        export const updateBulletinType = (obj: contentMgTypes.bulletin.INav): AxiosPromise<IResponse<contentMgTypes.bulletin.INav>> => {
            return http.put<IResponse<contentMgTypes.bulletin.INav>>('/announcement/config', obj)
        }
        //新增公告
        export const addBulletin = (obj: contentMgTypes.bulletin.IAddBulletinInfo): AxiosPromise<IResponse<contentMgTypes.bulletin.IAddBulletinInfo>> => {
            return http.post<IResponse<contentMgTypes.bulletin.IAddBulletinInfo>>('/announcement', obj)
        }

        //公告列表
        export const getBulletinList = (obj: contentMgTypes.bulletin.IReqData): AxiosPromise<IResponse<contentMgTypes.bulletin.IOptionData>> => {
            return http.post<IResponse<contentMgTypes.bulletin.IOptionData>>('/announcement/query', obj)
        }
        //公告详情
        export const getBulletinDetail = (code: string): AxiosPromise<IResponse<contentMgTypes.bulletin.IAddBulletinInfo>> => {
            return http.get<IResponse<contentMgTypes.bulletin.IAddBulletinInfo>>(`/announcement/detail?code=${code}`)
        }
        //更新公告
        export const updateBulletin = (obj: contentMgTypes.bulletin.IAddBulletinInfo): AxiosPromise<IResponse<contentMgTypes.bulletin.IAddBulletinInfo>> => {
            return http.put<IResponse<contentMgTypes.bulletin.IAddBulletinInfo>>('/announcement', obj)
        }
        //删除公告
        export const deleteBulletins = (arr: string[]): AxiosPromise<IResponse<any>> => {
            return http.post<IResponse<any>>('/announcement/delete', arr)
        }
    }

    export namespace useAudit {
        export const getList = (req: contentMgTypes.useAudit.IQueryReq): AxiosPromise<IResponse<contentMgTypes.useAudit.IQueryRes>> => {
            return http.post<IResponse<contentMgTypes.useAudit.IQueryRes>>(`/flowstep/exportaudit/list-audittask`, req)
        }
        export const getKeyframe = (contentIds: string[]): AxiosPromise<IResponse<contentMgTypes.useAudit.IUseAuditListItem[]>> => {
            return http.post<IResponse<contentMgTypes.useAudit.IUseAuditListItem[]>>(`/flowstep/exportaudit/list-audittask-extend`, contentIds)
        }
    }

    export namespace repeatAuditApi {
        //获取全部来源
        export const getClipsourceList = (): AxiosPromise<IResponse<contentMgTypes.repeatAuditTypes.IClipsourceListItem[]>> => {
            return http.get<IResponse<contentMgTypes.repeatAuditTypes.IClipsourceListItem[]>>(`/config/get-all-clipsource`)
        }
        export const deleteItem = (params:any): AxiosPromise<IResponse<any>> => {
            return http.post<IResponse<any>>('/search/delete',params)
        }
        //查询待审总数
        export const getWaitTotal = (): AxiosPromise<IResponse<number>> => {
            return http.get<IResponse<number>>(`/zjcustom/content-filter/unaudit/count`)
        }

        export const queryList = (params: contentMgTypes.repeatAuditTypes.IQueryReq): AxiosPromise<IResponse<contentMgTypes.repeatAuditTypes.IQueryRes>> => {
            return http.post<IResponse<contentMgTypes.repeatAuditTypes.IQueryRes>>(`/search/full-search`, params)
        }
        //查询记录: 审核通过/不通过
        // export const queryRecordList = (params: contentMgTypes.repeatAuditTypes.IQueryReq): AxiosPromise<IResponse<contentMgTypes.repeatAuditTypes.IQueryRes>> => {
        //     return http.post<IResponse<contentMgTypes.repeatAuditTypes.IQueryRes>>(`/zjcustom/content-filter/query/record`, params)
        // }
        //审核通过/审核打回
        export const auditResult = (req:contentMgTypes.repeatAuditTypes.IAuditResultReq): AxiosPromise<IResponse<any>> => {
            return http.post<IResponse<any>>(`/zjcustom/content-filter/audit`, req)
        }
        //检测结果中的获取查重信息
        export const getRepeatResult = (contentId: string): AxiosPromise<IResponse<contentMgTypes.repeatAuditTypes.IRepeatResult>> => {
            return http.get<IResponse<contentMgTypes.repeatAuditTypes.IRepeatResult>>(`/check/duplicate?contentId=${contentId}`)
        }
        // //查询素材的基本信息-改为单独调接口查询
        // export const getEntityMeta = (contentId: string): AxiosPromise<IResponse<IFormItem[]>> => {
        //     return http.get<IResponse<IFormItem[]>>(`/zjcustom/content-filter/entitydata?contentId=${contentId}`)
        // }
        //获取嵌套表格数据
        export const getChildrenList = (contentId: string): AxiosPromise<IResponse<contentMgTypes.repeatAuditTypes.IChildrenListItem[]>> => {
            return http.get<IResponse<contentMgTypes.repeatAuditTypes.IChildrenListItem[]>>('/check/duplicate-clip?contentId=' + contentId)
        }
        //发起编目： 审核通过后自动发起编目
        export const catalogCreate = (contentIds: string[]): AxiosPromise<IResponse<any[]>> => {
            return http.post<IResponse<any[]>>(`/catalogue/v2/task/create`,contentIds, {headers:{hideLoading: true}})
        }
        //获取元数据某项的配置项
        export const getMetadataFieldSet = (fieldName: string): AxiosPromise<IResponse<ccTypes.IMetadataFieldSetItem[]>> => {
            return http.get<IResponse<ccTypes.IMetadataFieldSetItem[]>>(`/metadatafield/fixitems?fieldName=${fieldName}`);
        }
        //依据id获取单个元数据配置项: 栏目/频道等特殊枚举字段
        export const getMetadataFieldSetFromId = (fieldId: number): AxiosPromise<IResponse<contentMgTypes.repeatAuditTypes.IMetadataFieldSetFromIdItem[]>> => {
            return http.get<IResponse<contentMgTypes.repeatAuditTypes.IMetadataFieldSetFromIdItem[]>>(`/metadatafield/fixitems-by-id?fixItemId=${fieldId}`);
        }
    }
}

export default contentMgApi
