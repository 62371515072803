import BaseStore from 'src/stores/base/baseStore';
import entityTypes from 'src/types/entityTypes';
import { observable, action, runInAction, computed } from 'mobx';
import entityApi from 'src/apis/entityApi';
import { Stores } from "../../index";
import { playerUtil } from 'mam-common-utils';
import { message } from 'antd';

export default class ReviewInfoStore extends BaseStore<Stores> {
    // 审片信息数据
    @observable
    public reviewInfo: entityTypes.IReviewReuslt[] = []
    @observable
    public newReviewInfo: entityTypes.IReviewReuslt[] = []
    /** 秒 */
    @observable
    public playerCurrentTime: number = 0
    @observable
    public descGroups: entityTypes.IDescGroup[] = []
    @observable
    public newDescGroups: entityTypes.IDescGroup[] = []
    @observable
    public selectedFilter: string[] = []
    @observable
    public newSelectedFilter: string[] = []
    @observable
    public reivewInfoColors: entityTypes.IReivewInfoColorRes[] = []
    @observable
    public isLoadingReviewData = false;
    private isAddedListener: boolean

    @action
    public getReviewData(contentId: string) {
        return new Promise((resolve, reject)=>{
            this.isLoadingReviewData = true;
            entityApi.getReivewResult(contentId).then(res => {
                runInAction(() => {
                    if (res.data.data) {
                        res.data.data.forEach((s)=>{
                            let group = this.descGroups.find(f => f.value === s.description);
                            if (!group) {
                                group = {
                                    value: s.description,
                                    label: s.description
                                }
                                this.descGroups.push(group)
                            }
                        })
                        this.reviewInfo = res.data.data;
                        this.selectedFilter = this.descGroups.map(s => s.value);
                        this.isLoadingReviewData = false;
                        resolve();
                    }
                })
            }, res=>{
                if (res.data.error && res.data.error.title){
                    message.error(res.data.error.title);
                }
                reject();
            });
        })
    }

    @action
    public getNewReviewData(contentId: string) {
        return new Promise((resolve, reject)=>{
            this.isLoadingReviewData = true;
            entityApi.getNewReivewResult(contentId).then(res => {
                runInAction(() => {
                    if (res.data.data) {
                        res.data.data.forEach((s)=>{
                            let group = this.newDescGroups.find(f => f.value === s.description);
                            if (!group) {
                                group = {
                                    value: s.description,
                                    label: s.description
                                }
                                this.newDescGroups.push(group)
                            }
                        })
                        this.newReviewInfo = res.data.data;
                        this.newSelectedFilter = this.newDescGroups.map(s => s.value);
                        this.isLoadingReviewData = false;
                        resolve();
                    }
                })
            }, res=>{
                if (res.data.error && res.data.error.title){
                    message.error(res.data.error.title);
                }
                reject();
            });
        })
    }
    @action
    public changeFilter(checkedValues: string[]) {
        this.selectedFilter = checkedValues
    }

    @action
    public newChangeFilter(checkedValues: string[]) {
        this.newSelectedFilter = checkedValues
    }

    @action
    public getReviewInfoColor(){
        entityApi.getReivewInfoColor().then(res=>{
            runInAction(()=>{
                if (res.data.data){
                    this.reivewInfoColors = res.data.data;
                }
            })
        }, res=>{
            if (res.data.error && res.data.error.title){
                message.error(res.data.error.title);
            }
        })
    }

    public getAnalysisTypeByCode(code: string){
        switch(code){
            case 'frame':
                return '画面';
            case 'ocr':
                return '字幕';
            case 'asr':
                return '语音';
            case 'tech':
                return '技术';
            default : return '';
        }
    }

    public watchPlayer() {
        if (!this.isAddedListener && this.stores.entityStore.viewer && this.stores.entityStore.viewer.player) {
            this.isAddedListener = true
            this.stores.entityStore.viewer.player.media.addEventListener('accurateTimeUpdate', (res: any) => {
                runInAction(() => {
                    this.playerCurrentTime = this.stores.entityStore.viewer.player.getCurrentTime()
                })
            })
        }
    }

    private getL100Ns(obj: any, l100NsKey: string, frameKey: string) {
        // 是否返回百纳秒
        if (obj[l100NsKey] === -1) {
            // 没有返回则取帧号 转换为百纳秒
            if (obj[frameKey] !== null && obj[frameKey] !== undefined && obj[frameKey] > -1) {
                return playerUtil.frame2L100Ns(obj[frameKey], this.stores.entityStore.frameRate);
            } else {
                return 0;
            }
        } else {
            return obj[l100NsKey]
        }
    }
}   