import { http } from "../utils/http";
import { AxiosPromise } from "axios";
import entityTypes from "../types/entityTypes";
import ccTypes from "../types/ccTypes";
import {IResponse} from "mam-core-react/dist/modules/http";
import { SmartMetadataType } from 'src/stores/entity/extends/smartViewStore';
import IFormItem from "mam-metadata-react/dist/types/IFormItem";
import iEntityTypes from 'src/types/iEntityTypes';

namespace entityApi {
    export const getInitializeData = (contentId: string, isCatalog?: string): AxiosPromise<IResponse<entityTypes.IEntity>> => {
        return http.get<IResponse<entityTypes.IEntity>>('/entity/initialize-data?pathtype=1&permission=1&contentId=' + contentId + '&shortUrl=' + (isCatalog ? '&isCatalog=' + isCatalog : ''));
    }

    /** 获取元数据信息 */
    export const getEntityData = (contentId: string, entityType: string, permission?: string): AxiosPromise<IResponse<IFormItem[]>> => {
        return http.get<IResponse<IFormItem[]>>('/entity/get-entity-base-data?pathtype=1&permission='+(permission || 1)+'&contentId=' + contentId + '&entityType=' + entityType);
    }

    export const getCatalog = (contentId: string, entityType: string, frameRate: number, programform: string): AxiosPromise<IResponse<entityTypes.ICatalog[]>> => {
        return http.get<IResponse<entityTypes.ICatalog[]>>('/entity/get-catalog?pathtype=1&entityType=' + entityType +
            '&contentId=' + contentId + '&frameRate=' + frameRate + '&programform=' + programform);
    }

    export const updateEntityData = (request: entityTypes.IUpdateEntityReq): AxiosPromise<IResponse<any>> => {
        return http.post<IResponse<any>>('/entity/update-entity-data', request);
    }

    export const updateKeyframe = (request: entityTypes.IUpdateKeyframeReq): AxiosPromise<IResponse<any>> => {
        return http.post<IResponse<any>>('/entity/update-keyframes', request);
    }

    /** 后台接口返回的keyFrameNo为百纳秒 */
    export const getKeyframes = (contentId: string, site: string, entityType: string, frameRate: number): AxiosPromise<IResponse<entityTypes.IMarKeyframe[]>> => {
        return http.get<IResponse<entityTypes.IMarKeyframe[]>>('/entity/get-markkeyframes?contentId=' + contentId + '&queryAdress=' + site + '&entityType=' + entityType + '&frameRate=' + frameRate);
    }

    export const deleteKeyframes = (req: entityTypes.IDeleteKeyframeReq) => {
        return http.post<IResponse<any>>('/entity/delete-keyframes', req);
    }

    export const updateTag = (request: entityTypes.IUpdateTagReq): AxiosPromise<IResponse<any>> => {
        return http.post<IResponse<any>>('/entity/update-tag', request);
    }

    export const getHistory = (contentId: string, site: string): AxiosPromise<IResponse<entityTypes.IHistory[]>> => {
        return http.get<IResponse<entityTypes.IHistory[]>>('/entity/get-log-by-contentId?contentId=' + contentId + '&queryAdress=' + site);
    }

    export const updateClip = (request: entityTypes.IUpdateClipReq): AxiosPromise<IResponse<any>> => {
        return http.post<IResponse<any>>('/entity/update-audio-scene', request);
    }

    export const getClips = (contentId: string, site: string, entityType: string, frameRate: number): AxiosPromise<IResponse<entityTypes.IEntityScenes[]>> => {
        if (entityType === 'video') {
            return http.get<IResponse<entityTypes.IEntityScenes[]>>('/entity/get-audio-scenes?pathtype=1&contentId=' + contentId +
                '&entityType=video&queryAdress=' + site + '&frameRate=' + frameRate);
        }
        else {
            return http.get<IResponse<entityTypes.IEntityScenes[]>>('/entity/get-audio-scenes?contentId=' + contentId + '&entityType=audio&queryAdress=' + site);
        }
    }

    export const deleteClip = (request: entityTypes.IDeleteClipReq): AxiosPromise<IResponse<any>> => {
        return http.post<IResponse<any>>('/entity/delete-audio-scene', request);
    }

    export const updateCatalog = (request: entityTypes.IUpdateCatalogReq): AxiosPromise<IResponse<any>> => {
        return http.post<IResponse<any>>('/entity/update-catalog', request);
    }

    export const getRelatedMaterial = (contentId: string, pageIndex: number, pageSize: number, title: string, keyword: string, type: string, partInfo?: entityTypes.contentIndex.IMetaOriginal): AxiosPromise<IResponse<ccTypes.cloudTypes.IFullSearchRes>> => {
        let request: entityTypes.IRelatedMaterialReq = { 'contentId': contentId, 'pageIndex': pageIndex, 'pageSize': pageSize, 'title': title, 'keyword': keyword, 'type': type };
        if (partInfo){
            request.partInfo = partInfo;
            request.partInfo.keyframePath = '';
        }
        return http.post<IResponse<ccTypes.cloudTypes.IFullSearchRes>>('/search/get-related-material', request);
    }

    export const getExternalsystemTemplate = (): AxiosPromise<IResponse<entityTypes.IExternalsystemTemplate[]>> => {
        return http.get<IResponse<entityTypes.IExternalsystemTemplate[]>>('/policy/get-externalsystem-template');
    }
    // 查询当前的封面
    export const getCoverpages = (contentId: string, site: string, fileType: string, frameRate: string): AxiosPromise<IResponse<any>> => {
        return http.get('/entity/get-frontcover?contentId=' + contentId + '&queryAdress=' + site + '&entityType=' + fileType + '&frameRate=' + frameRate)
    }
    // 上传封面的接口
    export const uploadCoverpage = (data: entityTypes.IUploadCoverpage): AxiosPromise<IResponse<any>> => {
        return http.post('/entity/add-frontcover', data)
    }
    // 更新封面的接口，设置某一项为封面
    export const updateCoverpage = (data: entityTypes.IUpdateCoverpage): AxiosPromise<IResponse<any>> => {
        return http.post('/entity/update-frontcover', data)
    }
    // 删除封面
    export const deleteCoverpage = (data: entityTypes.IUpdateCoverpage): AxiosPromise<IResponse<any>> => {
        return http.post('/entity/delete-frontcover', data)
    }

    // 片段导出
    export const exportSection = (data: entityTypes.IExportSectionReq): AxiosPromise<IResponse<any>> => {
        return http.post('/export/exporttask', data)
    }

        // 剪切保存
    export const clipSave = (data: entityTypes.IClipSaveReq): AxiosPromise<IResponse<any>> => {
        return http.post('/material/trim-update', data)
    }
    
    // 片段另存为:获取转换模板,保存格式
    export const getTransCodeTemplate = (): AxiosPromise<IResponse<entityTypes.ITransCodeTemplateTemplate[]>> => {
        return http.get('/export/get-transcodetemplate')
    }
    export const getSaveSectionMetadata = (): AxiosPromise<IResponse<IFormItem[]>> => {
        return http.get('/export/story-config')
    }
    // 验证素材的版权信息
    // 传参为素材的contentId数组
    export const validCopyrights = (data: string[]): AxiosPromise<IResponse<any>> => {
        return http.post('/copyright/valid-copyrights', data)
    }

    // 视频片段另存为
    export const videoSaveSection = (data: entityTypes.ISaveSectionReq): AxiosPromise<IResponse<any>> => {
        return http.post('/export/savevideoclip', data)
    }

    /** 获取关联素材 */
    export const getLinkedEntity = (contentId: string, entityType: string): AxiosPromise<IResponse<entityTypes.ILinkedEntity[]>> => {
        return http.get<IResponse<entityTypes.ILinkedEntity[]>>('/entity/get-relation-entity?pathtype=1&permission=1&contentId=' + contentId + '&entityType=' + entityType)
    }

    /** 根据id获取相关素材所有id */
    export const getLinkedInfo = (contentId: string, entityType: string): AxiosPromise<IResponse<iEntityTypes.IRundownItem[]>> => {
        return http.get<IResponse<iEntityTypes.IRundownItem[]>>('/entity/get-relation-entityinfo?pathtype=1&permission=1&contentId=' + contentId + '&entityType=' + entityType)
    }

    // /** 根据id项，获取关联素材 */
    // export const getLinkedInfo = (id: string): AxiosPromise<IResponse<entityTypes.ILinkedEntity[]>> => {
    //     return http.get<IResponse<entityTypes.ILinkedEntity[]>>('/entity/get-relation-entity/' + id)
    // }

    /** 获取文稿内容 */
    export const getScriptContent = (contentId: string, entityType: string): AxiosPromise<IResponse<entityTypes.IScriptContent[]>> => {
        return http.get<IResponse<entityTypes.IScriptContent[]>>('/entity/get-script-info?pathtype=1&permission=1&contentId=' + contentId + '&entityType=' + entityType)
    }

    /** 获取人物描述 */
    export const getPersonDesc = (key: string): AxiosPromise<IResponse<string[]>> => {
        return http.get<IResponse<string[]>>('/intelligentProcess/description?term=' + key);
    }

    /** 保存智能信息 */
    export const updateIntelligentResult = (requset: entityTypes.characterRecognitionTypes.ICharacterRecognitionRequset | entityTypes.characterRecognitionTypes.ISmartMetadatasRequest, siteCode: string = 'S1', isCatalog?: string): AxiosPromise<IResponse<string>> => {
        return http.post<IResponse<string>>(`/api/entity/update-intelligentresult?siteCode=${siteCode}` + (isCatalog ? '&isCatalog=' + isCatalog : ''), requset)
    }
    /* 提取标签 */
    export const extractTag = (contentId: string): AxiosPromise<IResponse<string>> => {
        return http.get<IResponse<string>>(`/intelligentProcess/get-relationscript-tag?contentid=${contentId}`)
    }

    /** 批量保存智能信息 */
    export const patchUpdateIntelligentResult = (requset: entityTypes.SmartInfo.IPatchUpdateIntelligentReq, siteCode: string = 'S1'): AxiosPromise<IResponse<string>> => {
        return http.post<IResponse<string>>(`/api/entity/patch-update-intelligentresult?siteCode=${siteCode}`, requset)
    }

    /** 获取智能相关元数据信息 */
    export const getMetadatas = (contentId: string, isCatalog?: string): AxiosPromise<IResponse<entityTypes.contentIndex.IMetadata[]>> => {
        return http.get<IResponse<entityTypes.contentIndex.IMetadata[]>>('/entity/get-metadata?pathtype=1&permission=1&contentId=' + contentId + '&shortUrl=' + (isCatalog ? '&isCatalog=' + isCatalog : ''));
    }

    /** 根据type查询metadata */
    export const getMetadataByType = (contentId: string, type: SmartMetadataType): AxiosPromise<IResponse<entityTypes.contentIndex.IMetadata[]>> => {
        return http.get<IResponse<entityTypes.contentIndex.IMetadata[]>>(`/entity/get-metadata?contentId=${contentId}&type=${type}`);
    }

    export const getTermDetail = (id: string, name: string, type: string) => {
        return http.get<IResponse<entityTypes.contentIndex.ITermDetail[]>>('/intelligentProcess/get-term-detail?id=' + (id || '') + '&term=' + name + '&type=' + type);
    }

    /** 获取审片数据信息 */
    export const getReivewResult = (contentId: string): AxiosPromise<IResponse<entityTypes.IReviewReuslt[]>> => {
        return http.get<IResponse<entityTypes.IReviewReuslt[]>>('/content-audit/result?contentId=' + contentId);
    }

    export const getNewReivewResult = (contentId: string): AxiosPromise<IResponse<entityTypes.IReviewReuslt[]>> => {
        return http.get<IResponse<entityTypes.IReviewReuslt[]>>('/content-audit/v2/smartaudit?contentId=' + contentId);
    }

    /** 查询附件信息 */
    export const getAttachment = (contentid: string): AxiosPromise<IResponse<entityTypes.IAttachmentRes>> => {
        return http.get<IResponse<entityTypes.IAttachmentRes>>('/entity/get-entity-attachment?contentid=' + contentid);
    }
    /** 删除附件 */
    export const deleteAttachment = (contentId: string, fileGuid: string): AxiosPromise<IResponse<any>> => {
        return http.post<IResponse<any>>('/entity/delete-attachment', {
            basicInfo: {
                'contentId': contentId
            },
            updateInfo: [
                fileGuid
            ]
        });
    }

    /** 查询标签关联片段 */
    export const searchTagRelevantPart = (tag: string, guid: string): AxiosPromise<IResponse<ccTypes.searchTypes.ISmartQueryResultData[]>> => {
        return http.post<IResponse<ccTypes.searchTypes.ISmartQueryResultData[]>>('/search/search-tag-relevant', {
            tag,
            size: 5,
            guid
        });
    }

    /** 导入语音SRT信息 */
    export const importVoiceSrt = (formData: FormData, siteCode: string): AxiosPromise<IResponse<ccTypes.searchTypes.ISmartQueryResultData[]>> => {
        return http.post<IResponse<ccTypes.searchTypes.ISmartQueryResultData[]>>('/api/entity/upload-srt?siteCode=' + siteCode, formData, {
            headers: {'content-type': 'application/x-www-form-urlencoded'}
        });
    }

    /** 查询词库列表 */
    export const queryLexiconList = (req: entityTypes.IQLexiconReq): AxiosPromise<IResponse<entityTypes.IQLexiconRes>> => {
        return http.post<IResponse<entityTypes.IQLexiconRes>>('/business/lexicon/lexicon-sets-search', req);
    }

    /** 查询粗精切信息 */
    export const getCuttings = (contentId: string): AxiosPromise<IResponse<ccTypes.searchTypes.IQueryResultData[]>> => {
        return http.get<IResponse<ccTypes.searchTypes.IQueryResultData[]>>('/search/gneral-detail?contentId=' + contentId);
    }

    /** 获取粗精切素材的元数据信息 */
    export const getCuttingMetadata = (contentId: string, entityType: string): AxiosPromise<IResponse<any>> => {
        return http.get<IResponse<any>>('/entity/get-entity-base-data?pathtype=1&permission=1&contentId=' + contentId + '&entityType=' + entityType);
    }

    /** 修改未知人脸 */
    export const modifyUnknowFace = (req: entityTypes.IModifyUnknowFaceReq): AxiosPromise<IResponse<any>> => {
        return http.put<IResponse<any>>('/knowledgebase/unknowface', req);
    }

    export const getPersonCategorys = (): AxiosPromise<IResponse<entityTypes.IPersonCategory[]>> => {
        return http.get<IResponse<entityTypes.IPersonCategory[]>>('/knowledgebase/people/categorys');
    }
    /** 附件预览页面获取附件信息 */
    export const getAttachmentInfo = (contentId: string, fileGuid: string): AxiosPromise<IResponse<entityTypes.IAttachmentRes>> => {
        return http.get<IResponse<entityTypes.IAttachmentRes>>('/entity/get-entity-attachment-preview?contentId=' + contentId + '&fileGuid=' +fileGuid);
    }
    /** 重新生成预览文件 */
    export const rewriteFilegroup = (req: entityTypes.IRewriteFilegroupReq): AxiosPromise<IResponse<any>> => {
        return http.post<IResponse<any>>('/export/rewrite-filegroup', req);
    }

    /** 获取审片数据信息 */
    export const getReivewInfoColor = (): AxiosPromise<IResponse<entityTypes.IReivewInfoColorRes[]>> => {
        return http.get<IResponse<entityTypes.IReivewInfoColorRes[]>>('/content-audit/config/color');
    }

    /** 查询评论 */
    export const getScoreRecord = (param: entityTypes.IScoreRecordReq): AxiosPromise<IResponse<entityTypes.IScoreRecordRes>> => {
        return http.post<IResponse<entityTypes.IScoreRecordRes>>('/content/score/record', param);
    }

    /** 添加评论 */
    export const addScoreRecord = (param: entityTypes.IAddScoreRecordReq): AxiosPromise<IResponse<entityTypes.IScoreRecordRes>> => {
        return http.post<IResponse<entityTypes.IScoreRecordRes>>('/content/score/add', param);
    }

    /* 添加片段*/
    export const addCatelog = (data: any):AxiosPromise<IResponse<any>> => {
        return http.post('/search_pickedup/add', data)
    }
    
    //获取关联专题
    export const getRelatedTopics = (contentId:string):AxiosPromise<IResponse<entityTypes.IRelatedTopicsData[]>> => {
        return http.get<IResponse<entityTypes.IRelatedTopicsData[]>>(`/aialbum/bread-crumbs/${contentId}`)
    } 
}
export default entityApi;
