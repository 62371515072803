import { Stores } from 'src/stores';
import ICatalogStore from '../iCatalogStore';
import { observable, action } from 'mobx';
import iEntityTypes from 'src/types/iEntityTypes';
import _ from 'lodash'
import ContentIndexStore from './contentIndexStore';

export default class ContentIndexManualStore extends ContentIndexStore {
    @observable
    public currentModifyPart: iEntityTypes.IMetaOriginal | undefined;

    public constructor(stores: Stores, catalogStore: ICatalogStore) {
        super(stores, catalogStore);
    }

    @action
    public setCurrentModifyPart(part?: iEntityTypes.IMetaOriginal){
        this.currentModifyPart = part;
    }

    @action
    public setCurrentModifyPartTrim(trimIn: number, trimOut: number){
        if (this.currentModifyPart){
            this.currentModifyPart.inpoint = parseInt((trimIn).toFixed(7).replace('.', ''), 10)
            this.currentModifyPart.outpoint = parseInt((trimOut).toFixed(7).replace('.', ''), 10)
        }
    }
}