import {action, observable, runInAction, computed} from "mobx";
import entityTypes from "../../types/entityTypes";
import _ from 'lodash';
import SmartViewStore from "../iEntity/extends/smartViewStore";
import KeyframeStore from "./extends/keyframeStore";
import FaceRecoStore from "./extends/faceRecoStore";
import CombinePartStore from "./extends/combinePartStore";
import IFormItem from "mam-metadata-react/dist/types/IFormItem";
import catalogTypes from 'src/types/catalogTypes';
import MetadataForm from 'mam-metadata-react/dist/metadataForm';
import { Stores } from 'src/stores';
import iEntityTypes from 'src/types/iEntityTypes';
import ExtendStore from '../base/extendStore';
import ContentIndexStore from './extends/contentIndexStore';
import iCatalogApi from 'src/apis/iCatalogApi';
import iCatalogTypes from 'src/types/iCatalogTypes';
import ContentIndexManualStore from './extends/contentIndexManualStore';
import { Modal, message } from 'antd';
import { IMetaValidateAllResult } from 'mam-metadata-react/dist/types/metadataValidation';
import { l } from 'mam-core-react';
import entityApi from 'src/apis/entityApi';
import iEntityApi from 'src/apis/iEntityApi';

export default class ICatalogStore extends ExtendStore {
    @observable
    public params: catalogTypes.ICatalogEntityParam;
    @observable
    public viewer: any;
    @observable
    public entity: iCatalogTypes.ICatalogEntity;
    @observable
    public nameField?: IFormItem
    /** 查询素材详情报错信息 */
    @observable
    public loadError?: string
    /** 视频帧率 */
    public frameRate: number = 0;
    /** 智能相关元数据信息 */
    @observable
    public metadatas: entityTypes.contentIndex.IMetadata[];
    /** 关键帧字典，可通过帧号寻找关键帧 */
    @observable
    public keyframeDict: any;
    @observable
    public selectedTab: string = 'baseInfo';
    @observable
    public editable: boolean = true;
    @observable
    public leftBottomModule: string = 'keyframeInfo';
    @observable
    public isShiftMode: boolean = false;
    /** 结构化 */
    public contentIndexStore: ContentIndexStore;
    /** 人工编目结构化 */
    public contentIndexManualStore: ContentIndexManualStore;
    /** 人脸、语音、字幕相关 */
    public smartViewStore: SmartViewStore;
    /** 关键帧相关操作 */
    public keyframeStore: KeyframeStore;
    /** 人脸识别相关操作 */
    public faceRecoStore: FaceRecoStore;
    /** 片段合并相关操作 */
    public combinePartStore: CombinePartStore;
    /** 编目信息 */
    @observable
    public catalog: entityTypes.ICatalog[];
    /** 是否显示右侧滑动面板 */
    @observable
    public showRight: boolean = false;
    /** 当前活跃的contentIndexStore，当前有两套结构化数据，人工或智能，为了取值冲突，只能采取这种方式 */
    @observable
    public activeContentIndexStore: ContentIndexStore;
    //切换（镜头层，场景层，片段层）
    @observable
    public isType: number;
    @observable
    public form: MetadataForm | undefined
    public hasModifiedForm: boolean = false;

    public constructor(stores: Stores) {
        super(stores);
        this.contentIndexStore = new ContentIndexStore(this.stores, this);
        this.activeContentIndexStore = this.contentIndexStore;
        this.smartViewStore = new SmartViewStore(this.stores, this);
        this.keyframeStore = new KeyframeStore(this.stores, this);
        this.faceRecoStore = new FaceRecoStore(this.stores, this);
        this.combinePartStore = new CombinePartStore(this.stores, this);
        this.contentIndexManualStore = new ContentIndexManualStore(stores, this);
    }

    @action
    public setParams(params: catalogTypes.ICatalogEntityParam) {
        this.params = params;
    }
    @action
    public setType(type:number) {
        this.isType = type;
    }

    @action
    public setViewer(viewer: any) {
        this.viewer = viewer;
    }

    @action
    public getInitializeData(contentId: string): Promise<iCatalogTypes.ICatalogEntity>{
        return new Promise((resolve, reject) => {
            let defer;
            if (this.params.taskId){
                defer = iCatalogApi.getInitializeData(this.params.contentId, this.params.taskId);
            }
            else {
                defer = entityApi.getInitializeData(contentId, this.params.isCatalog);
            }
            defer.then(res=>{
                runInAction(() => {
                    if (res.data.data) {
                        this.entity = res.data.data as any;
                        this.entity.contentId = contentId;
                        this.nameField = _.find(this.entity.entitydata, {
                            fieldName: 'name_'
                        });
                        this.entity.entityName = !this.nameField ? '' : this.nameField.value as string;
                        this.frameRate = this.entity.paths && this.entity.paths.length > 0 ? this.entity.paths[0].frameRate : 0;
                        if (!this.frameRate){//如果paths里没有帧率，则到元数据里取
                            const frameRateMeta = _.find(this.entity.entitydata, {fieldName: 'framerate'});
                            if (frameRateMeta && frameRateMeta.value){
                                this.frameRate = frameRateMeta.value as number;
                            }
                        }
                        this.initKeyframeDict(this.entity.fileGroups);
                        resolve(this.entity);
                    }
                    else {
                        reject();
                    }
                });
            }).catch((res)=>{
                runInAction(() => {
                    if (res.data.error.code === 'B1066') {
                        this.loadError = '系统未定义该类型，无法浏览。';
                    } else {
                        this.loadError = res.data.error.title;
                    }
                    reject();
                });
            });
        });
    }

    @action
    public setLoadError(msg: string){
        this.loadError = msg;
    }

    @action
    public setEditable(editable: boolean) {
        this.editable = editable;
    }

    @action
    public initKeyframeDict(fileGroups: entityTypes.IFileGroup[]) {
        this.keyframeDict = {};
        fileGroups.forEach(groupItem => {
            if (!groupItem || groupItem.groupName !== 'keyframe_') {
                return;
            }
            groupItem.fileItems.forEach(fileItem => {
                if (fileItem.fileFormat.keyFrameFormat) {
                    this.keyframeDict[fileItem.fileFormat.keyFrameFormat.keyFrameNo] = {
                        keyframePath: fileItem.filePath
                    };
                }
            });
        });
    }

    /** 切换片段是否check状态 */
    @action
    public switchPartCheckStatus(part: iEntityTypes.IMetaOriginal, check: boolean){
        this.contentIndexStore.switchPartCheckStatus(part, check);
    }

    @action
    public setEntityData(entityData: IFormItem[]){
        this.entity.entitydata = entityData;
    }

    @action
    public changeTab(tabKey: string) {
        this.selectedTab = tabKey
    }

    @action
    public setLeftBottomModule(moduleName: string){
        this.leftBottomModule = moduleName;
    }

    @action
    public setShiftMode(mode: boolean){
        this.isShiftMode = mode;
    }

    @action
    public setEntity(entity: iCatalogTypes.ICatalogEntity){
        this.entity = entity
    }

    @action
    public setForm(form:MetadataForm){
        this.form = form
        return this.form
    }

    public saveMetadata(){
        return new Promise((resolve, reject)=>{
            const currentMeta = this.getActiveContentIndexStore.currentMeta;
            // 保存基本元数据
            if (!currentMeta && this.form) {
                this.form.validateAll().then((ret: IMetaValidateAllResult) => {
                    let promise;
                    // 带流程的保存
                    if (this.params.taskId){
                        promise = iCatalogApi.saveEntityData(ret.items, this.params.contentId, this.params.taskId)
                    }
                    // 无流程的保存
                    else {
                        let baseInfoReq: entityTypes.IUpdateEntityReq = {
                            operateSite: this.params.site,
                            operaType: "update",
                            basicInfo: {
                                contentId: this.entity.contentId,
                                entityType: this.entity.type,
                                groupname: this.entity.type + 'group',
                                entityName: this.nameField ? this.nameField.value as string : '',
                                frameRate: this.entity.paths && this.entity.paths.length > 0 ?
                                this.entity.paths[0].frameRate : 0
                            },
                            updateInfo: {
                                newValue: ret.items,
                                oldValue: this.entity.entitydata
                            }
                        };
                        promise = entityApi.updateEntityData(baseInfoReq)
                    }
                    promise.then(() => {
                        message.success(l('entity.modifySuccess', '修改成功'));
                        this.getInitializeData(this.params.contentId);
                        this.setHasModifiedForm(false);
                        runInAction(()=>{
                            this.showRight = false;
                        })
                        resolve();
                    });
                }, ()=>{
                    reject();
                });
            }
            else if (currentMeta && this.form){
                this.form.validateAll().then((ret: IMetaValidateAllResult) => {
                    let promise;
                    // 带流程的保存
                    if (this.params.taskId){
                        promise = iCatalogApi.saveAiMetadata(ret.items, currentMeta.storyid ? 'story' : (currentMeta.sceneid ? 'scene' : 'shot'), this.params.contentId, currentMeta.shotid || currentMeta.sceneid || currentMeta.storyid, this.params.taskId)
                    }
                    // 无流程的保存
                    else {
                        if (currentMeta.storyid){
                            promise = iEntityApi.updateStoryMetadata(this.params.contentId, currentMeta.storyid, ret.items)
                        }
                        else if (currentMeta.sceneid){
                            promise = iEntityApi.updateSceneMetadata(this.params.contentId, currentMeta.sceneid, ret.items)
                        }
                        else if (currentMeta.shotid) {
                            promise = iEntityApi.updateShotMetadata(this.params.contentId, currentMeta.shotid, ret.items)
                        }
                    }
                    if (promise){
                        promise.then(() => {
                            message.success(l('entity.modifySuccess', '修改成功'));
                            this.contentIndexStore.getExpandedDatas();
                            this.contentIndexManualStore.getExpandedDatas(true);
                            this.setHasModifiedForm(false);
                            runInAction(()=>{
                                this.showRight = false;
                            })
                            resolve();
                        });
                    }
                    else {
                        reject();
                    }
                }, ()=>{
                    reject();
                });
            }
        })
    }

    /** 询问是否需要保存本次修改的元数据 */
    public popAskSaveMetadata(done: ()=>void){
        if (!this.hasModifiedForm){
            done();
            return;
        }
        Modal.confirm({
            title: '提示'.l('com.tips'),
            content: '结构层数据修改后未保存，是否仍要离开当前页面？'.l('iCatalog.askLeaveThisMetadataModify'),
            okText: '确认'.l('com.confirm'),
            cancelText: '取消'.l('com.cancel'),
            onOk: ()=> {
                this.setHasModifiedForm(false);
                this.getActiveContentIndexStore.setStoryId(undefined);
                this.getActiveContentIndexStore.setSceneId(undefined);
                this.getActiveContentIndexStore.setShotId(undefined);
                done();
            },
            onCancel: ()=>{
            }
        })
    }

    @action
    public setShowRight(show: boolean){
        this.showRight = show;
    }

    public setHasModifiedForm(modify: boolean){
        this.hasModifiedForm = modify;
    }

    @action
    public setActiveContentIndexStore(store: ContentIndexStore){
        this.activeContentIndexStore = store;
    }

    @computed
    public get getActiveContentIndexStore(){
        return this.activeContentIndexStore;
    }

    public getCurrentStore(){
        return this;
    }
}
