import BaseStore from 'src/stores/base/baseStore';
import { observable, action, runInAction } from 'mobx';
import entityTypes from 'src/types/entityTypes';
import ccTypes from 'src/types/ccTypes';
import _ from 'lodash';
import entityApi from "../../../apis/entityApi";
import ccApi from 'src/apis/ccApi';
import { Stores } from "../../index";
import CatalogStore from "../../catalog/catalogStore";
import playerUtil from "mam-common-utils/dist/modules/playerUtil";
import { message } from 'antd';
import EntityStore from 'src/stores/entity/entityStore';
import IEntityStore from '../iEntityStore';
import iEntityApi from 'src/apis/iEntityApi';
import iEntityTypes from 'src/types/iEntityTypes';
import ICatalogStore from '../../iCatalog/iCatalogStore';
import RegularSettingStore from './regularSettingStore';
import iCatalogApi from 'src/apis/iCatalogApi';

export enum SmartMetadataType {
    story = 'story',
    scene = 'scene',
    shot = 'shot'//镜头层
}

export default class SmartViewStore extends BaseStore<Stores> {
    /** 视频帧率 */
    public frameRate: number = 0
    @observable
    public selectedPerson: iEntityTypes.IFaceAidata | undefined
    @observable
    public selectedCaption: string

    /** 智能分析数据 */
    @observable
    public smartMetadatas: entityTypes.SmartInfo.ISmartMetadatas;
    /** 关键帧以帧号为key的字典 */
    // @observable
    // public keyframeDict: { [key: number]: entityTypes.IKeyframeInfo; } = {}
    /** 智能人物列表，person.tsx顶部显示的那些头像列表 */
    @observable
    public smartPerson: iEntityTypes.IFaceAidata[] = []
    @observable
    public smartVoice: iEntityTypes.ISpeechAidataRes | undefined
    @observable
    public smartSubtitle: iEntityTypes.ISpeechAidataRes | undefined
    // 包含此人物的视频
    @observable
    public containePerson: ccTypes.searchTypes.IFullSearchRes | undefined = {
        queryResult: {
            pageIndex: 0,
            pageTotal: 0,
            pageSize: 0,
            recordTotal: 0,
            data: []
        },
        facets: [],
        sortFields: [],
        usedTime: 0
    }
    @observable
    public selectedSegment: iEntityTypes.IFaceSequence | undefined;
    /** 当前显示的人脸类型 */
    @observable
    public currPersonFaceViewType: entityTypes.SmartInfo.FaceViewType = entityTypes.SmartInfo.FaceViewType.KNOWN
    /** 是否正在加载人脸数据 */
    @observable
    public isLoadingFaces = true;
    @observable
    public isLoadingVoices = false;
    @observable
    public isLoadingSubtitles = false;

    public regularSettingStore: RegularSettingStore;

    public parentStore: IEntityStore | EntityStore | CatalogStore | ICatalogStore;

    private watchPlayerTmLock: boolean = false;

    public constructor(stores: Stores, parentStore: IEntityStore | EntityStore | CatalogStore | ICatalogStore) {
        super(stores)
        this.parentStore = parentStore;
        this.regularSettingStore = new RegularSettingStore(stores, this);
    }

    //获取人脸信息
    @action
    public getFaces(inpoint?: number, outpoint?: number){
        return new Promise((resolve, reject)=>{
            this.isLoadingFaces = true;
            this.smartPerson = [];
            this.changePerson(undefined);
            this.setSelectedSegment(undefined);
            iEntityApi.getFaceAidata(this.getEntityStore().params.contentId, inpoint, outpoint).then(res=>{
                runInAction(()=>{
                    if (res.data.data && res.data.data.data.length > 0){
                        //无管理员权限，则屏蔽unnamed的人脸
                        // if (!this.stores.userStore.currentUser?.isAdmin){
                        //     res.data.data.data = res.data.data.data.filter((item: any)=>{
                        //         return item.name !== 'unnamed';
                        //     })
                        // }
                        res.data.data.data = _.orderBy(res.data.data.data, ['isKnown'], ['desc']);
                        this.smartPerson = res.data.data.data;
                        this.isLoadingFaces = false;
                        resolve();
                    }
                    else {
                        this.isLoadingFaces = false;
                        resolve();
                    }
                })
            }, (res)=>{
                if (res.data.error && res.data.error.title){
                    message.error(res.data.error.title);
                    reject();
                }
            });
        })
    }

    //获取语音信息
    @action
    public getVoices(page?: number, inpoint?: number, outpoint?: number, keyword?: string){
        return new Promise((resolve, reject)=>{
            if (this.isLoadingVoices){
                return;
            }
            this.isLoadingVoices = true;
            if (page){
                this.smartVoice = undefined;
            }
            let qPage = page || 1;
            if (this.smartVoice && this.smartVoice.pageIndex){
                qPage = this.smartVoice.pageIndex + 1;
                if (qPage > this.smartVoice.pageTotal){
                    return;
                }
            }
            let reqDefer;
            if (this.getEntityStore() instanceof ICatalogStore && (this.getEntityStore() as ICatalogStore).params.taskId){
                reqDefer = iCatalogApi.getSpeechOrSubtitleAidata(qPage, keyword ? 5000 : 100, 'speech', this.getEntityStore().params.contentId, (this.getEntityStore() as ICatalogStore).params.taskId, inpoint, outpoint, keyword);
            }
            else {
                reqDefer = iEntityApi.getSpeechAidata(this.getEntityStore().params.contentId, qPage, keyword ? 5000 : 100, inpoint, outpoint, keyword);
            }
            reqDefer.then(res=>{
                runInAction(()=>{
                    if (res.data.data){
                        if (qPage > 1 && this.smartVoice){
                            this.smartVoice.data = this.smartVoice.data.concat(res.data.data.data);
                            this.smartVoice.pageIndex = res.data.data.pageIndex;
                            this.smartVoice.pageSize = res.data.data.pageSize;
                            this.smartVoice.pageTotal = res.data.data.pageTotal; 
                            this.smartVoice.recordTotal = res.data.data.recordTotal;
                        }
                        else{
                            this.smartVoice = res.data.data;
                        }
                        this.isLoadingVoices = false;
                        resolve();
                    }
                    else {
                        this.isLoadingVoices = false;
                        resolve();
                    }
                })
            }, (res)=>{
                if (res.data.error && res.data.error.title){
                    message.error(res.data.error.title);
                }
                reject();
            });
        })
    }

    //获取字幕信息
    @action
    public getSubtitles(page?: number, inpoint?: number, outpoint?: number, keyword?: string){
        return new Promise((resolve, reject)=>{
            if (this.isLoadingSubtitles){
                return;
            }
            this.isLoadingSubtitles = true;
            if (page){
                this.smartSubtitle = undefined;
            }
            let qPage = page || 1;
            if (this.smartSubtitle && this.smartSubtitle.pageIndex){
                qPage = this.smartSubtitle.pageIndex + 1;
                if (qPage > this.smartSubtitle.pageTotal){
                    return;
                }
            }
            let reqDefer;
            if (this.getEntityStore() instanceof ICatalogStore && (this.getEntityStore() as ICatalogStore).params.taskId){
                reqDefer = iCatalogApi.getSpeechOrSubtitleAidata(qPage, keyword ? 5000 : 100, 'subtitle', this.getEntityStore().params.contentId, (this.getEntityStore() as ICatalogStore).params.taskId, inpoint, outpoint, keyword);
            }
            else {
                reqDefer = iEntityApi.getSubtitleAidata(this.getEntityStore().params.contentId, qPage, keyword ? 5000 : 100, inpoint, outpoint, keyword)
            }
            reqDefer.then(res=>{
                runInAction(()=>{
                    if (res.data.data){
                        if (qPage > 1 && this.smartSubtitle){
                            this.smartSubtitle.data = this.smartSubtitle.data.concat(res.data.data.data);
                            this.smartSubtitle.pageIndex = res.data.data.pageIndex;
                            this.smartSubtitle.pageSize = res.data.data.pageSize;
                            this.smartSubtitle.pageTotal = res.data.data.pageTotal; 
                            this.smartSubtitle.recordTotal = res.data.data.recordTotal;
                        }
                        else{
                            this.smartSubtitle = res.data.data
                        }
                        this.isLoadingSubtitles = false;
                        resolve();
                    }
                    else {
                        this.isLoadingSubtitles = false;
                        resolve();
                    }
                })
            }, (res)=>{
                if (res.data.error && res.data.error.title){
                    message.error(res.data.error.title);
                }
                reject();
            });
        })
    }

    @action
    public changePerson(id: string | undefined) {
        return new Promise((resolve, reject)=>{
            if (id === undefined) {
                this.selectedPerson = undefined;
                this.setSelectedSegment(undefined);
                resolve();
                return;
            }
            this.selectedPerson = this.smartPerson.find(s => s.id === id);
            this.getPersonDesc();
            if (this.selectedPerson) {
                iEntityApi.getFaceSequence(this.parentStore.params.contentId, this.selectedPerson.personId, this.selectedPerson.clusterId).then(res=>{
                    runInAction(()=>{
                        if (res.data.data && this.selectedPerson){
                            this.selectedPerson.totalTime = playerUtil.second2TC(res.data.data.faceDuration / Math.pow(10, 7), 'video', res.data.data.frameRate);
                            this.selectedPerson.appearanceRate = parseFloat((res.data.data.faceDuration / res.data.data.duration * 100).toFixed(2));
                            this.selectedPerson.segments = res.data.data.data;
        
                            if (this.selectedPerson.segments && this.selectedPerson.segments.length > 0) {
                                this.setSelectedSegment(this.selectedPerson.segments[0]);
                            }
                        }
                        resolve();
                    })
                }, (res)=>{
                    if (res.data.error && res.data.error.title){
                        message.error(res.data.error.title);
                    }
                    reject();
                });
            }
            else {
                reject();
            }
        })
    }
    @action
    public changeCaption(id: string) {
        this.selectedCaption = id
    }
    // 获取包含此人物的视频
    @action
    public getContainPerson = (name: string) => {
        let reqData: ccTypes.searchTypes.IFullSearchReq = {
            conditions: [{ field: "type_", value: ["video"] }],
            keyword: [name],
            page: 1,
            size: 6,
            sortBys: [{ fieldName: "default", isDesc: false }]
        }
        ccApi.searchApi.fullSearch(reqData).then((result) => {
            runInAction(() => {
                this.containePerson = result.data.data
            })
        }).catch((err) => {
            console.log(err)
        });
    }

    public watchPlayer() {
        if (this.getEntityStore().viewer && this.getEntityStore().viewer.player) {
            this.getEntityStore().viewer.player.media.addEventListener('accurateTimeUpdate', (res: any) => {
                if (this.watchPlayerTmLock){
                    return;
                }
                this.watchPlayerTmLock = true;
                setTimeout(()=>{
                    this.getSelectedCaption()
                    this.watchPlayerTmLock = false;
                }, 500)
            })
        }
    }

    public getSelectedCaption() {
        let seconds = this.getEntityStore().viewer.player.getCurrentTime()
        let n100s = parseInt(seconds.toFixed(7).replace('.', ''), 10);
        let next: iEntityTypes.ISpeechAidata | undefined;
        if (this.getSelectedTab() === 'voiceInfo') {
            next = this.smartVoice ? this.smartVoice.data.find(s => s.inpoint <= n100s && s.outpoint >= n100s) : undefined
        } else if (this.getSelectedTab() === 'subtitleInfo') {
            next = this.smartSubtitle ? this.smartSubtitle.data.find(s => s.inpoint <= n100s && s.outpoint >= n100s) : undefined
        }
        this.changeCaption(next ? next.id : '')
    }

    @action
    public addSmartPerson(person: iEntityTypes.IFaceAidata) {
        this.smartPerson.push(person);
    }

    @action
    public updateSmartVoiceItemName(item: iEntityTypes.ISpeechAidata, name: string) {
        item.text = name;
    }

    @action
    public updateSmartSubtitleItemName(item: iEntityTypes.ISpeechAidata, name: string) {
        item.text = name;
    }
    @action
    public setSelectedSegment(seg: iEntityTypes.IFaceSequence | undefined) {
        this.selectedSegment = seg;
        if (this.selectedSegment) {
            if (this.getEntityStore().viewer && this.getEntityStore().viewer.player && this.selectedSegment.inpoint !== undefined) {
                this.getEntityStore().viewer.player.setCurrentTime(this.selectedSegment.inpoint / Math.pow(10, 7));
            }
        }
        // const entityStore = this.getEntityStore();
        // const frameRate = entityStore.frameRate;
        // this.selectedSegment = seg;
        // if (this.selectedSegment) {
        //     if (this.getEntityStore().viewer && this.getEntityStore().viewer.player && this.selectedSegment.inpoint !== undefined) {
        //         this.getEntityStore().viewer.player.setCurrentTime(playerUtil.frame2Second(this.selectedSegment.KeyframeNO, frameRate));
        //     }
        // }
    }

    private getPersonDesc() {
        if (this.selectedPerson) {
            entityApi.getTermDetail(this.selectedPerson.personId, this.selectedPerson.name, 'NAME').then((res) => {
                runInAction(() => {
                    if (this.selectedPerson && res.data.data) {
                        this.selectedPerson.desc = {
                            text: res.data.data[0].description,
                            tags: res.data.data[0].tag || []
                        }
                    }
                });
            })
        }
    }

    private getCatalogStore() {
        return (this.parentStore as EntityStore).contentIndexStore || this.parentStore as CatalogStore;
    }

    private getEntityStore() {
        return this.parentStore;
    }

    private getSelectedTab() {
        if (this.getEntityStore() instanceof IEntityStore) {
            return (this.getEntityStore().selectedTab as entityTypes.ISelectedTab).top;
        }
        else {
            return this.getEntityStore().selectedTab as string
        }
    }
}
