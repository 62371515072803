import iCatalogTypes from 'src/types/iCatalogTypes'
import { AxiosPromise } from 'axios'
import { IResponse, http } from 'mam-core-react/dist/modules/http'
import iEntityTypes from 'src/types/iEntityTypes'
import IFormItem from 'mam-metadata-react/dist/types/IFormItem'

type aiDataType = 'story' | 'scene' | 'shot' | 'face' | 'speech' | 'subtitle'

namespace iCatalogApi {
    /** 查询基本元数据 */
    export const getInitializeData = (contentId: string, taskId?: string):AxiosPromise<IResponse<iCatalogTypes.ICatalogEntity>> => {
        return http.get<IResponse<iCatalogTypes.ICatalogEntity>>(`/catalogue/v2/initialize-data?contentId=${contentId}&taskId=${taskId || ''}`)
    }
    export const getAiData = (type: aiDataType, contentId: string, taskId?: string, parentStruct?: aiDataType, id?: string, isManual?: boolean):AxiosPromise<IResponse<iCatalogTypes.IAiDataRes>> => {
        return http.get<IResponse<iCatalogTypes.IAiDataRes>>(`/catalogue/v2/metadata/${type}?contentId=${contentId}&taskId=${taskId || ''}&parentStruct=${parentStruct || ''}&id=${id || ''}` + (isManual !== undefined ? `&isManual=${isManual}` : ''))
    }
    export const getSpeechOrSubtitleAidata = (page: number, pageSize: number, type: 'speech' | 'subtitle', contentId: string, taskId?: string, inpoint?: number, outpoint?: number, keyword?: string):AxiosPromise<IResponse<iCatalogTypes.ISpeechOrSubtitleAidataRes>> => {
        return http.get<IResponse<iCatalogTypes.ISpeechOrSubtitleAidataRes>>(`/catalogue/v2/metadata/${type}?pageIndex=${page}&pageSize=${pageSize}&contentId=${contentId}&taskId=${taskId || ''}` + (keyword ? `&keyword=${keyword}` : '') + (inpoint !== undefined ? `&inpoint=${inpoint}` : '') + (outpoint !== undefined ? `&outpoint=${outpoint}` : ''))
    }
    /** 更新智能数据 */
    export const saveAiData = (data: any, metadataType: string, contentId: string, taskId?: string):AxiosPromise<IResponse<any>> => {
        return http.patch<IResponse<any>>(`/catalogue/v2/metadata/${metadataType}?contentId=${contentId}&taskId=${taskId || ''}`, [data])
    }
    export const getPartMetadata = (type: aiDataType,id: string, contentId: string, taskId?: string): AxiosPromise<IResponse<IFormItem[]>> => {
        return http.get<IResponse<IFormItem[]>>(`/catalogue/v2/metadata/${type}/item?contentId=${contentId}&taskId=${taskId || ''}&id=${id}`)
    }
    export const mergePart = (currentType: string, params: iCatalogTypes.IMergeReq):AxiosPromise<IResponse<any>> => {
        return http.post<IResponse<any>>(`/catalogue/v2/merge/${currentType}`, params)
    }

    /** 无taskId片段合并接口调用 */
    export const mergeStory = (params: iCatalogTypes.IMergeNoTaskReq):AxiosPromise<IResponse<any>> => {
        return http.post<IResponse<any>>('/aidata/story/merge', params)
    }
    export const mergeScene = (params: iCatalogTypes.IMergeNoTaskReq):AxiosPromise<IResponse<any>> => {
        return http.post<IResponse<any>>('/aidata/scene/merge', params)
    }
    export const mergeShot = (params: iCatalogTypes.IMergeNoTaskReq):AxiosPromise<IResponse<any>> => {
        return http.post<IResponse<any>>('/aidata/shot/merge', params)
    }

    export const modifyAiDataInoutPoint = (metadataType: string, params: iCatalogTypes.IModifyAiDataInoutPointReq)=>{
        return http.patch<IResponse<any>>(`/catalogue/v2/structure/mark/${metadataType}`, params)
    }

    export const deleteAiData = (metadataType: string, contentId: string, ids: string[], taskId?: string):AxiosPromise<IResponse<any>> => {
        let paramIds = '';
        ids.forEach((id)=>{
            paramIds += 'ids=' + id + '&';
        })
        paramIds = paramIds.substring(0, paramIds.length - 1);
        return http.delete(`/catalogue/v2/metadata/${metadataType}/item?contentId=${contentId}&taskId=${taskId || ''}&` + paramIds)
    }

    export const movePart = (params: iCatalogTypes.IMoveReq):AxiosPromise<IResponse<any>> => {
        return http.post<IResponse<any>>(`/catalogue/v2/move/${params.mergeTarget}`, params)
    }

    /** 无taskId片段移动接口调用 */
    export const moveScene = (params: iCatalogTypes.IMoveNoTaskReq):AxiosPromise<IResponse<any>> => {
        return http.post<IResponse<any>>('/aidata/scene/move', params)
    }
    export const moveShot = (params: iCatalogTypes.IMoveNoTaskReq):AxiosPromise<IResponse<any>> => {
        return http.post<IResponse<any>>('/aidata/shot/move', params)
    }

    /** 更新基本元数据 */
    export const saveEntityData = (items: IFormItem[], contentId: string, taskId?: string):AxiosPromise<IResponse<any>> => {
        return http.put<IResponse<any>>(`/catalogue/v2/entitydata?contentId=${contentId}&taskId=${taskId || ''}`, items)
    }

    export const createAiMetadata = (metadataType: 'story' | 'scene' | 'shot', param: iCatalogTypes.ICreateAiMetadata)=>{
        return http.post<IResponse<any>>(`/catalogue/v2/metadata/${metadataType}/item`, param);
    }

    /** 更新片段元数据 */
    export const saveAiMetadata = (items: IFormItem[], metadataType: string, contentId: string, id: string, taskId?: string):AxiosPromise<IResponse<any>> => {
        return http.put<IResponse<any>>(`/catalogue/v2/metadata/${metadataType}/item?contentId=${contentId}&id=${id}&taskId=${taskId || ''}`, items)
    }

    export const getKeyframes = (taskId: number): AxiosPromise<IResponse<iCatalogTypes.IKeyframesRes>> => {
        return http.get<IResponse<iCatalogTypes.IKeyframesRes>>(`/catalogue/v2/keyframe?taskId=${taskId}`)
    }

    export const addKeyframe = (keyframe: iCatalogTypes.IKeyframe): AxiosPromise<IResponse<any>> => {
        return http.post<IResponse<any>>('/catalogue/v2/keyframe', keyframe)
    }

    export const updateKeyframe = (keyframe: iCatalogTypes.IKeyframe): AxiosPromise<IResponse<any>> => {
        return http.patch<IResponse<any>>('/catalogue/v2/keyframe', keyframe)
    }

    export const deleteKeyframe = (taskId: number, keyframeNo: number): AxiosPromise<IResponse<any>> => {
        return http.delete(`/catalogue/v2/keyframe?taskId=${taskId}&keyframeNo=${keyframeNo}`)
    }

    export const setPartManualState = (currentType: string, params: iCatalogTypes.ISetPartManualStateReq):AxiosPromise<IResponse<any>> => {
        return http.patch<IResponse<any>>(`/catalogue/v2/manual/${currentType}`, params)
    }

    export const getHistory = (taskId: number): AxiosPromise<IResponse<iCatalogTypes.IHistoryItem[]>> => {
        return http.get<IResponse<iCatalogTypes.IHistoryItem[]>>(`/catalogue/v2/modify-history?taskId=${taskId}`)
    }

    export const getContentStructCount = (taskId: string, isManual: boolean): AxiosPromise<IResponse<iEntityTypes.IContentStructCountRes>> => {
        return http.get<IResponse<iEntityTypes.IContentStructCountRes>>(`/catalogue/v2/structure/count?taskid=${taskId}&isManual=${isManual}`);
    }

    /** 编目继承 */
    export const cloneStructure = (params: iCatalogTypes.ICloneStructureReq):AxiosPromise<IResponse<any>> => {
        return http.patch<IResponse<any>>(`/catalogue/v2/template/clone/structure`, params)
    }

    /** 查询模板列表 */
    export const getTemplates = (config?: any): AxiosPromise<IResponse<iCatalogTypes.ITemplatesRes[]>> => {
        return http.get<IResponse<iCatalogTypes.ITemplatesRes[]>>(`/catalogue/v2/template`, config ? config : {});
    }

    /** 保存编目模板 */
    export const saveCatalogTemplate = (params: iCatalogTypes.ISaveCatalogTemplateReq):AxiosPromise<IResponse<any>> => {
        return http.post<IResponse<any>>(`/catalogue/v2/template`, params)
    }

    /** 应用编目模板 */
    export const applyCatalogTemplate = (params: iCatalogTypes.IApplyCatalogTemplateReq):AxiosPromise<IResponse<any>> => {
        return http.patch<IResponse<any>>(`/catalogue/v2/template/apply`, params)
    }

    /** 删除编目模板 */
    export const deleteCatalogTemplate = (templateName: string):AxiosPromise<IResponse<any>> => {
        return http.delete(`/catalogue/v2/template?templateName=${templateName}`)
    }
}

export default iCatalogApi