import ExtendStore from "../base/extendStore"
import { Stores } from '..';
import LeftNavStore from './leftNavStore';
import ProcessMonitoringStore from './subModules/processMonitoring/processMonitoringStore'
import NavConfigStore from './subModules/navConfig/navConfigStore'
import { observable, action } from 'mobx';
import SearchTemplateStores from './subModules/search/searchTemplateStore/searchTemplateStore';
import contentMgTypes from 'src/types/contentMgTypes';

export default class ManageCenterStore extends ExtendStore {
   public leftNavStore:LeftNavStore
   public processMonitoringStore:ProcessMonitoringStore
   public navConfigStore:NavConfigStore
   public searchTemplateStores: SearchTemplateStores
   @observable
   public iframeInfo: contentMgTypes.IIframeInfo = {
       src: '',
       show: false
   };

   public constructor(stores: Stores){
      super(stores);
      this.leftNavStore = new LeftNavStore(stores, this)
      this.processMonitoringStore = new ProcessMonitoringStore(stores, this)
      this.navConfigStore = new NavConfigStore(stores)
      this.searchTemplateStores = new SearchTemplateStores(stores, this);
   }

   @action
    public setIframeInfo(info: contentMgTypes.IIframeInfo){
        this.iframeInfo = info;
    }
}
