import BaseStore from "../../base/baseStore";
import {action, observable, runInAction, computed} from "mobx";
import {Stores} from "../../index";
import * as React from "react";
import {CheckboxChangeEvent} from "antd/lib/checkbox";
import ICatalogStore from '../iCatalogStore';
import { message } from 'antd';
import _ from 'lodash'
import iCatalogTypes from 'src/types/iCatalogTypes';
import iCatalogApi from 'src/apis/iCatalogApi';
import iEntityApi from 'src/apis/iEntityApi';

export default class KeyframeStore extends BaseStore<Stores> {
    @observable
    public markeyframes: iCatalogTypes.IKeyframe[];
    /** 当前正在编辑的标记点 */
    @observable
    public currentKeyframe?: iCatalogTypes.IKeyframe;
    private parentStore: ICatalogStore

    public constructor(stores: Stores, parentStore: ICatalogStore) {
        super(stores);
        this.parentStore = parentStore;
    }

    /** 获取按入出点过滤的标记点 */
    @computed
    public get filterKeyframes(): iCatalogTypes.IKeyframe[] | undefined{
        if (!this.parentStore.entity || !this.markeyframes){
            return undefined;
        }
        const currentMeta = this.parentStore.contentIndexStore.currentMeta;
        if (currentMeta){
            const inpoint = currentMeta.inpoint
            const outpoint = currentMeta.outpoint
            return this.markeyframes.filter((keyframe)=>{
                let l100ns = parseInt(keyframe.inpoint.toFixed(7).replace('.',''), 10)
                return l100ns >= inpoint && l100ns <= outpoint;
            })
        }
        else {
            return this.markeyframes;
        }
    }

    /**
     * 显示新增标记点界面
     */
    @action
    public showCreateKeyframe(newKeyframe: iCatalogTypes.IKeyframe) {
        const frameRate = this.parentStore.entity.paths && this.parentStore.entity.paths.length > 0
            ? this.parentStore.entity.paths[0].frameRate : 0;
        this.setCurrentKeyframe(newKeyframe);
    }

    @action
    public setCurrentKeyframe(keyframe?: iCatalogTypes.IKeyframe) {
        this.currentKeyframe = keyframe;
    }

    @action
    public onCurrentKeyframeDescChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        if (this.currentKeyframe){
            this.currentKeyframe.description = e.target.value;
        }
    }

    @action
    public handleKeyframeIsIconFrame = (e: CheckboxChangeEvent) => {
        if (this.currentKeyframe){
            this.currentKeyframe.isIconFrame = e.target.checked;
        }
    }

    /** 显示指定标记点界面 */
    @action
    public showKeyframeTab(keyframe: iCatalogTypes.IKeyframe, edit: boolean) {
        this.setCurrentKeyframe(keyframe);
    }

    @action
    public getKeyframes(){
        return new Promise((resolve, reject)=>{
            if (this.parentStore.params.taskId){
                iCatalogApi.getKeyframes(parseInt(this.parentStore.params.taskId, 10)).then(res=>{
                    runInAction(() => {
                        if (res.data.data){
                            this.markeyframes = res.data.data.data;
                        }
                        resolve();
                    });
                }, res=>{
                    if (res.data.error && res.data.error.title){
                        message.error(res.data.error.title);
                    }
                    reject();
                });
            }
            else {
                iEntityApi.getKeyframes(this.parentStore.params.contentId).then(res=>{
                    runInAction(() => {
                        if (res.data.data){
                            this.markeyframes = res.data.data.data as any;
                        }
                        resolve();
                    });
                }, res=>{
                    if (res.data.error && res.data.error.title){
                        message.error(res.data.error.title);
                    }
                    reject();
                });
            }
        })
    }

    @action
    public updateKeyframeBase64(inpoint: number, base64: string){
        let item = _.find(this.markeyframes, {inpoint});
        if (item){
            item.imageBase64 = base64;
        }
    }
}