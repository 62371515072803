import {action, computed, observable, runInAction} from "mobx";
import {IEntityType, IConfig} from "../../../types/configTypes";
import uploadTypes from "../../../types/uploadTypes";
import {Stores} from "../../index";
import uploadApi from "../../../apis/uploadApi";
import BaseStore from "../../base/baseStore";
import {ISelectAllStore} from "../selectAllStore";
import SelectAllStore from "../selectAllStore";
import PicPkgStore from "./picPkgStore";
import GroupStore from "./groupStore";
import _ from 'lodash';
import commonUtil from "mam-common-utils/dist/modules/commonUtil";
import IFormItem from "mam-metadata-react/dist/types/IFormItem";
import moment from 'moment';
import { getValidationItems } from 'mam-metadata-react/dist/controls/mfcBaseControl';
import {validateItems} from 'mam-core-react'

export interface IUploadStore{
    uploadStore: UploadStore;

    initUploadStore : (stores: Stores) => void;
}

export default class UploadStore extends BaseStore<Stores> implements ISelectAllStore {
    @observable
    public showGeneral: boolean;
    @observable
    public showPicPkg: boolean;
    @observable
    public showGroup: boolean;
    @observable
    public options: uploadTypes.IUploadOptions;
    @observable
    public transferType : number = uploadTypes.TransferTypes.WEB;
    @observable
    public tasks: uploadTypes.ITask[];
    @observable
    public selectedTask?: uploadTypes.ITask;
    /** 选中任务的临时元数据，非实时修改元数据，避免取消修改后元数据不能还原 */
    @observable
    public selectedTaskTempMetaItems?: IFormItem[]
    /** 选中任务的临时上传的附件 */
    @observable
    public selectedTaskAttachmentFiles?: uploadTypes.IFile[]
    /** 所有素材类型元数据 */
    @observable
    public allMetadataField: uploadTypes.IMetadataFieldRes;
    @observable
    public entityAllTypes: IEntityType[] = [];
    /** 是否批量编辑模式 */
    @observable
    public multiEditMode: boolean = false;
    /** 批量编辑临时元数据 */
    @observable
    public multiEditMeta: IFormItem[];

    public uploadConfig: uploadTypes.IUpoladConfig;

    public selectAllStore: SelectAllStore;

    public picPkgStore: PicPkgStore;

    public groupStore: GroupStore;

    constructor(sts: Stores){
        super(sts);
        this.initSelectAllStore(sts, 'this.tasks', 'checked', this);

        this.picPkgStore = new PicPkgStore(this);
        this.groupStore = new GroupStore(this);

        let uploadTransferType = sessionStorage.getItem('upload_transfer_type')
        if (uploadTransferType){
            this.transferType = parseInt(uploadTransferType, 10);
        }
    }

    public initSelectAllStore(sts: Stores, listSyntax: string, itemSelectKey: string, currentStore?: BaseStore<Stores>){
        this.selectAllStore = new SelectAllStore(sts, listSyntax, itemSelectKey, currentStore);
    }

    @action
    public getUploadConfig = () => {
        return new Promise((resolve, reject)=>{
            uploadApi.getUploadConfig()
            .then(res=>{
                if(res.data.data){
                    this.uploadConfig = res.data.data;
                }
                resolve();
            }).catch(()=>{
                reject();
            })
        })
    }

    @action
    public setShowGeneral(show: boolean){
        this.showGeneral = show;
    }

    @action
    public setShowPicPkg(show: boolean){
        this.showPicPkg = show;
    }

    @action
    public setShowGroup(show: boolean){
        this.showGroup = show;
    }

    @action
    public setOptions(options: uploadTypes.IUploadOptions){
        this.options = options;
    }

    @action
    public setTransferType(transferType: number){
        this.transferType = transferType;
        sessionStorage.setItem('upload_transfer_type', this.transferType.toString());
    }

    /** 更新任务列表顺序 */
    @action
    public updateTasksPos(tasks: uploadTypes.ITask[]){
        this.tasks = tasks;
    }

    @action
    public setTasks(tasks: uploadTypes.ITask[]){
        this.tasks = tasks;
        if (this.tasks && this.tasks.length > 0)
        {
            this.selectedTask = this.tasks[0];
        }
        this.initTasks(this.tasks);
    }

    @action
    public appendTasks(tasks: uploadTypes.ITask[]){
        this.initTasks(tasks);
        tasks.forEach((task)=>{
            this.tasks.push(task);
        });
    }

    @action
    public removeTask(task: uploadTypes.ITask){
        _.remove(this.tasks, (o: uploadTypes.ITask)=>{
            return o === task;
        })
    }

    /** 删除选中任务的某个文件 */
    @action
    public removeSelectedTaskFileByIdx(index: number){
        if (this.selectedTask && this.selectedTask.files)
        {
            this.selectedTask.files.splice(index, 1);
        }
    }

    /** 删除选中任务的某个文件 */
    @action
    public removeSelectedTaskFile(file: uploadTypes.IFile){
        if (this.selectedTask && this.selectedTask.files)
        {
            _.remove(this.selectedTask.files, (o: uploadTypes.IFile)=>{
                return o === file;
            });
        }
    }

    /** 修改选中任务的某个文件名称 */
    @action
    public editSelectedTaskFileName(file: uploadTypes.IFile, name: string){
        // 处理后缀
        let ext = (file.file as File).name.substring((file.file as File).name.lastIndexOf('.'), (file.file as File).name.length);
        if (name.indexOf(ext) === -1){
            name = name + ext;
        }

        file.fileName = name;
        let newFile = new File([file.file as File], name, {type: (file.file as File).type});
        file.file = newFile;
    }

    @computed
    public get getFilterTasks(): uploadTypes.ITask[]{
        let types = this.entityAllTypes.filter((type) => {
            return type.selected;
        });
        return this.tasks.filter((task) => {
            return _.find(types, { code: task.entityType }) != null;
        });
    }

    @computed
    public get getCheckedTasks(): uploadTypes.ITask[]{
        return this.getFilterTasks.filter((task) => {
            return task.checked;
        });
    }

    /** 获取选中并且过滤显示出来的任务 */
    @computed
    public get getCheckedVisibleTasks(): uploadTypes.ITask[]{
        return this.getCheckedTasks.filter((item)=>{
            if (this.options.taskType === uploadTypes.TaskTypes.GENERAL){
                if (_.findIndex(this.stores.webUploadStore.getCheckedFilterEntityTypes, {code: item.entityType}) > -1){
                    return true;
                }
                else {
                    return false;
                }
            }
            else {
                return true;
            }
        })
    }

    @action
    public initTasks(tasks: uploadTypes.ITask[]){
        if (tasks.length === 0){
            return;
        }
        tasks.forEach((task) => {
            task.checked = true;
            this.assembleMetadata(task);
            this.addEntityAllTypes(task);
        });
        this.selectAllStore.checkSelectAll();

        if (this.options.taskType === uploadTypes.TaskTypes.PICTUREPACKAGEUPLOAD && tasks[0].files)
        {
            this.picPkgStore.handleFiles(this.options, tasks, tasks[0].files).then((retFiles)=>{
                runInAction(()=>{
                    tasks[0].files = retFiles;
                });
            });
        }
        else if (this.options.taskType === uploadTypes.TaskTypes.GROUPUPLOAD && tasks[0].files)
        {
            this.groupStore.setVideo(undefined);
            this.groupStore.setAudios([]);
            tasks[0].files = this.groupStore.handleFiles(this.options, tasks, tasks[0].files);
        }
    }

    /** 为图片包或成组任务添加文件 */
    @action
    public appendFiles(files: uploadTypes.IFile[]){
        if (this.selectedTask) {
            if (!this.selectedTask.files) {
                this.selectedTask.files = [];
            }
            if (this.options.taskType === uploadTypes.TaskTypes.PICTUREPACKAGEUPLOAD) {
                this.picPkgStore.handleFiles(this.options, this.tasks, files).then((retFiles)=>{
                    runInAction(()=>{
                        retFiles.forEach((file) => {
                            if (this.selectedTask && this.selectedTask.files)
                            {
                                this.selectedTask.files.push(file);
                            }
                        });
                    });
                });
            }
            else if (this.options.taskType === uploadTypes.TaskTypes.GROUPUPLOAD)
            {
                let retFiles = this.groupStore.handleFiles(this.options, this.tasks, files);
                if (retFiles)
                {
                    retFiles.forEach((file) => {
                        if (this.selectedTask && this.selectedTask.files)
                        {
                            this.selectedTask.files.push(file);
                        }
                    });
                }
            }
        }
    }

    @action
    public setSelectedTask(task?: uploadTypes.ITask){
        this.selectedTask = task;
    }

    @action
    public setSelectedTaskTempMetaItems(items?: IFormItem[]){
        this.selectedTaskTempMetaItems = items;
    }

    @action
    public setSelectedTaskAttachmentFiles(attachmentFiles?: uploadTypes.IFile[]){
        this.selectedTaskAttachmentFiles = attachmentFiles;
    }
    @action
    public setSelectedTaskAttachmentFilesOk(attachmentFiles?: uploadTypes.IFile[]){
        if(this.selectedTask){
            this.selectedTask.attachmentFiles = attachmentFiles;
        }
    }
    @action
    public addSelectedTaskAttachmentFiles(newFiles: uploadTypes.IFile[]){
        if(this.selectedTaskAttachmentFiles){
            this.selectedTaskAttachmentFiles = this.selectedTaskAttachmentFiles.concat(newFiles)
        }else{
            this.selectedTaskAttachmentFiles = newFiles;
        }
    }

    @action
    public getAllMetadataField(source: number): Promise<any>{
        if (this.allMetadataField)
        {
            return Promise.resolve();
        }
        let req: uploadTypes.IMetadataFieldReq = {
            'source': 'yunpan'
        }
        if (source === uploadTypes.UploadModules.YUNPAN)
        {
            req.source = 'yunpan';
        }
        else if (source === uploadTypes.UploadModules.NECS)
        {
            req.source = 'necs';
        }
        else if (source === uploadTypes.UploadModules.WEBUPLOAD)
        {
            req.source = 'webupload';
        }
        return new Promise((resolve, reject)=>{
            uploadApi.getMetadataField(req).then((res)=>{
                runInAction(()=> {
                    if (res.data.success === true && res.data.data) {
                        this.allMetadataField = res.data.data;
                        resolve();
                    }
                    else
                    {
                        reject();
                    }
                });
            });
        });
    }

    @action
    public addEntityAllTypes(task: uploadTypes.ITask){
        if (_.find(this.entityAllTypes, { code: task.entityType }) == null) {
            let item = _.find(this.stores.configStore.config.entityTypes, { code: task.entityType });
            if (item)
            {
                item.selected = true;
                this.entityAllTypes.push(item);
            }
        }
    }

    @action
    public removeEntityAllTypes(task: uploadTypes.ITask){
        if (_.find(this.tasks, { entityType: task.entityType }) == null){
            _.remove(this.entityAllTypes, (o: IEntityType) => {
                return o.code === task.entityType;
            });
        }
    }

    @action
    public switchEntityTypeSelected(item: IEntityType){
        item.selected = !item.selected;
    }

    @action
    public setMultiEditMode(mode: boolean){
        this.multiEditMode = mode;
    }

    @action
    public setMultiEditMeta(meta: IFormItem[]){
        this.multiEditMeta = meta;
    }

    @action
    public setSelectedTaskMetaField(items: IFormItem[], index: number, value: string){
        if (this.selectedTask && this.selectedTask.metadata.field)
        {
            this.selectedTask.metadata.field = items;
            //修改提名需要更新task.metadata.name
            if (items[index].fieldName === 'name_'){
                this.selectedTask.metadata.name = value;
            }
        }
    }

    @action
    public setSelectedTaskMetaItems(items: IFormItem[]){
        if (this.selectedTask)
        {
            //特殊处理标题: 去首尾空格
            let nameField = _.find(items, {fieldName: 'name_'});
            if (nameField){
                nameField.value = (nameField.value as string).replace(/^\s+|\s+$/g, '')

                //修改题名需要更新task.metadata.name
                this.selectedTask.metadata.name = nameField.value;
            }
            this.selectedTask.metadata.field = items;
        }
    }

    @action
    public batchSetValueFromMultiEditMeta(){
        this.getCheckedTasks.forEach((task) => {
            if (task.metadata.field)
            {
                task.metadata.field.forEach((tf)=>{
                    let n = _.find(this.multiEditMeta, (fi: IFormItem) => {
                        return fi.fieldName === tf.fieldName && fi.fieldPath === tf.fieldPath;
                    });
                    if (n)
                    {
                        tf.value = n.value;
                    }

                    if (tf.fieldName === 'name_'){//修改了名称需要更新metadata.name
                        task.metadata.name = tf.value as string;
                    }
                });
            }
        });
    }

    @action
    public validTasksMetadata(){
        let allPromise: Array<Promise<any>> = []
        let addedTasks = this.getCheckedVisibleTasks.filter((item)=>{
            return item.status === 'added'
        })
        addedTasks.forEach((task) => {
            if (task.metadata.field){
                task.validErrs = [];
                task.metadata.field.filter((fieldItem)=>{
                    return fieldItem.isShow;
                }).forEach((fieldItem)=>{
                    let items = getValidationItems(fieldItem);
                    allPromise.push(new Promise((resolve, reject)=>{
                        validateItems(fieldItem, items).then((validErrs)=>{
                            resolve(undefined);
                        }, (validErrs)=>{
                            runInAction(()=>{
                                if (!task.validErrs){
                                    task.validErrs = [];
                                }
                                task.validErrs = task.validErrs.concat(validErrs.value);
                            })
                            reject();
                        });
                    }))
                })
            }
        });
        return Promise.all(allPromise);
    }

    private assembleMetadata(item: uploadTypes.ITask){
        let metadata = this.allMetadataField[item.entityType];
        if (item.entityType === 'video' && !(metadata instanceof Array))
        {
            metadata = metadata.data;
        }
        item.metadata.field = commonUtil.copyObj(metadata);

        let now = new Date();
        let specialFields: any = { // 特殊字段
            'name_': item.metadata.name,
            'filesize': !item.file && item.files ? item.files[0].fileSize : (item.file ? (item.file as File).size : 0),
            'fileext': !item.file && item.files ? ('.' + commonUtil.getExtension(item.files[0].fileName)) : (item.file ? commonUtil.getExtension((item.file as File).name) : ''),
            'createUser_': this.stores.userStore.currentUser ? this.stores.userStore.currentUser.nickName : '',
            'createDate_': moment(now).format('YYYY-MM-DD hh:mm:ss')
        }
        for (let sf in specialFields) {
            if (specialFields.hasOwnProperty(sf))
            {
                let field = _.find(item.metadata.field, (o: IFormItem) => {
                    return o.fieldName === sf;
                });
                if (field != null) {
                    field.value = specialFields[sf];
                }
            }
        }

        // 继承附加的field
        if (this.options.extraField !== undefined && this.options.extraField.length > 0) {
            this.options.extraField.forEach((e) => {
                let temp = _.find(item.metadata.field, (o: IFormItem) => {
                    return o.fieldName === e.fieldName;
                });
                if (temp != null && e.value != null)
                {
                    temp.value = e.value;
                }
            });
        }
    }
}
