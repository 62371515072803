import { BaseStore } from 'mam-core-react';
import { Stores } from 'src/stores';
import { observable, action, runInAction } from 'mobx';
import EntityStore from '../entityStore';
import entityTypes from 'src/types/entityTypes';
import iEntityApi from 'src/apis/iEntityApi';
import { message } from 'antd';
import iEntityTypes from 'src/types/iEntityTypes';

export default class PreviewImageStore extends BaseStore<Stores> {
    @observable
    public currentImg?: entityTypes.IPath;
    @observable
    public aiData: iEntityTypes.IPicAidataRes

    private parentStore: EntityStore;

    public constructor(stores: Stores, parentStore: EntityStore){
        super(stores)
        this.parentStore = parentStore;
    }

    @action
    public setCurrentImg(currentImg: entityTypes.IPath){
        this.currentImg = currentImg;
    }

    @action
    public getAiDatas(){
        if (this.currentImg){
            iEntityApi.getPicAidata(this.parentStore.params.contentId, this.currentImg.sourceFileGuid).then(res=>{
                runInAction(()=>{
                    if (res.data.data){
                        this.aiData = res.data.data;
                    }
                })
            }, res=>{
                if (res.data.error && res.data.error.title){
                    message.error(res.data.error.title);
                }
            });
        }
    }
}