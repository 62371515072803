import { AxiosPromise } from 'axios';
import { IResponse, http } from 'mam-core-react/dist/modules/http';
import manageCenterTypes from 'src/types/manageCenterTypes';

namespace manageCenterApi{
    export namespace processMonitoring{
        export const searchProcess = (params: manageCenterTypes.processMonitoring.ISearchProcessReq): AxiosPromise<IResponse<manageCenterTypes.processMonitoring.ISearchProcessRes>> => {
            return http.post<IResponse<manageCenterTypes.processMonitoring.ISearchProcessRes>>(`/mytask/v2/query`, params)
        }
        export const taskOperate = (types:string, params:number[],priority?:number): AxiosPromise<IResponse<any>> => {
            return http.post<IResponse<any>>(`/mytask/${types}`+(types === 'priority'? '/'+priority:''), params)
        }
    }
    export namespace search{
        export const getSearchTemplateFields = (): AxiosPromise<IResponse<manageCenterTypes.search.ISearchTemplateFieldsItem[]>> => {
            return http.get<IResponse<manageCenterTypes.search.ISearchTemplateFieldsItem[]>>(`/business/es-search-template/index-fields`)
        }

        export const getFixItemsById = (fixItemId: number): AxiosPromise<IResponse<manageCenterTypes.search.IFixItemsByIdRes[]>> => {
            return http.get<IResponse<manageCenterTypes.search.IFixItemsByIdRes[]>>(`/metadatafield/fixitems-by-id?fixItemId=${fixItemId}`)
        }

        export const querySearchTemplate = (): AxiosPromise<IResponse<any>> => {
            return http.get<IResponse<any>>(`/business/es-search-template`)
        }
        export const createSearchTemplate = (param: manageCenterTypes.search.ISearchTemplateItem): AxiosPromise<IResponse<any>> => {
            return http.post<IResponse<any>>(`/business/es-search-template`, param)
        }
        export const updateSearchTemplate = (param: manageCenterTypes.search.ISearchTemplateItem): AxiosPromise<IResponse<any>> => {
            return http.patch<IResponse<any>>(`/business/es-search-template`, param)
        }
        export const deleteSearchTemplate = (ids: string[]): AxiosPromise<IResponse<any>> => {
            let reqUrl = '/business/es-search-template'
            ids.forEach((id, index) => {
                if (index === 0) {
                    reqUrl += `?`
                } else {
                    reqUrl += `&`
                }
                reqUrl += `ids=${id}`
            })
            return http.delete(reqUrl)
        }
    }
}

export default manageCenterApi
