import {action, computed, observable, runInAction} from "mobx";
import entityApi from "../../apis/entityApi";
import EntityExtendStore from "./entityExtendStore";
import entityTypes from "../../types/entityTypes";
import { Stores } from "../index";
import _ from 'lodash';
import * as React from "react";
import CatalogInfo from "../../pages/newEntity/modules/rightTabContent/catalogInfo/catalogInfo";
import Copyright from "../../pages/newEntity/modules/rightTabContent/copyright";
import permissionUtil from "mam-common-utils/dist/modules/permissionUtil";
import playerUtil from "mam-common-utils/dist/modules/playerUtil";
import timeUtil from "mam-common-utils/dist/modules/timeUtil";
import IFormItem from "mam-metadata-react/dist/types/IFormItem";
import BaseInfo from 'src/pages/iEntity/modules/rightTabContent/baseInfo/baseInfo';
import MetadataForm from 'mam-metadata-react/dist/metadataForm';
import { message } from "antd";

export default class IEntityStore extends EntityExtendStore {
    @observable
    public params: entityTypes.IEntityParam
    @observable
    public viewer: any;
    @observable
    public entity: entityTypes.IEntity;
    @observable
    public nameField?: IFormItem
    /** 查询素材详情报错信息 */
    @observable
    public loadError?: string
    /** 错误码 */
    @observable
    public loadErrorCode?: string
    /** 选中的tab code */
    @observable
    public selectedTab: entityTypes.ISelectedTab = { top: 'baseInfo', bottom: 'keyframe' }
    /** 临时添加的tab信息，比如新增标记点tab等，新增完后tab会消失 */
    @observable
    public selectedTabObj: entityTypes.ISelectedTabObj = { code: '', name: '' }
    /** 元数据是否为编辑状态 */
    @observable
    public metaEditable: boolean = false;
    @observable
    public partMetaEditable: boolean = false;
    /** 视频帧率 */
    public frameRate: number = 0

    public baseInfo: React.RefObject<BaseInfo> = React.createRef();
    public baseInfoForm: React.RefObject<MetadataForm> = React.createRef();
    public catalogInfo: React.RefObject<CatalogInfo>;
    public copyright: React.RefObject<Copyright>;

    @observable
    public showRegeneratePreviewFileBtn: boolean = false;  //是否显示重新生成预览文件按钮

    @observable
    public isReqInitData: boolean = false;

    @observable
    public relatedTopicsData: entityTypes.IRelatedTopicsData[]
    /** 获取按入出点过滤的标记点 */
    @computed
    public get filterKeyframes(): entityTypes.IMarKeyframe[] | undefined{
        if (!this.entity || !this.entity.markeyframes){
            return undefined;
        }
        const inpoint = this.params.inpoint;
        const outpoint = this.params.outpoint;
        if (!inpoint || !outpoint){
            return this.entity.markeyframes;
        }
        return this.entity.markeyframes.filter((keyframe)=>{
            let l100ns = parseInt(keyframe.inpoint.toFixed(7).replace('.',''), 10)
            return l100ns >= inpoint && l100ns <= outpoint;
        })
    }

    public constructor(stores: Stores) {
        super(stores);
        this.initMediaStore();
        this.initRundownStore();
        this.initPreviewImageStore();
        this.initSmartViewStore();
        this.initContentIndexStore();
        this.initFavoriteStore(stores);
        this.initDownloadStore(stores);
        this.initBatchSmartProcess(stores);
        this.initOutstoreStore(stores);
        this.initReviewInfoStore();
        this.initCreatePlanningStore(stores);
        this.initDownloadStore(stores);
        this.initBatchExecuteSmartProcessStore(stores);
        this.initCuttingsStore();
        this.initCommentStore();
        this.initIntelligentTimelineStore();
        this.catalogInfo = React.createRef();
        this.copyright = React.createRef();
    }

    @action
    public setParams(params: any) {
        if (!this.params){
            this.params = params;
        }
        else {
            this.params = Object.assign({}, this.params, params);
        }
    }

    @action
    public setViewer(viewer: any) {
        this.viewer = viewer;
    }

    @action
    public getInitializeData(contentId: string): Promise<entityTypes.IEntity> {
        this.loadError = '';
        this.loadErrorCode = '';
        return new Promise((resolve, reject) => {
            this.isReqInitData = true;
            entityApi.getInitializeData(contentId).then((res) => {
                runInAction(() => {
                    if (res.data.data) {
                        this.entity = res.data.data;
                        this.entity.contentId = contentId;
                        this.nameField = _.find(this.entity.entitydata, {
                            fieldName: 'name_'
                        });
                        this.entity.entityName = !this.nameField ? '' : this.nameField.value as string;
                        this.entity.planDeleteTime = _.get(_.find(this.entity.entitydata, { fieldName: 'planDelTime_' }), 'value', '') as string;
                        this.entity.planDeleteTime = timeUtil.comingDateTime(this.entity.planDeleteTime, 'hour', 72 * 3600, 'min');
                        this.frameRate = this.entity.paths && this.entity.paths.length > 0 ? this.entity.paths[0].frameRate : 0;
                        //如果paths里没有帧率，则到元数据里取
                        if (!this.frameRate){
                            const frameRateMeta = _.find(this.entity.entitydata, {fieldName: 'framerate'});
                            if (frameRateMeta && frameRateMeta.value){
                                this.frameRate = frameRateMeta.value as number;
                            }
                        }
                        //处理时长
                        this.entity.duration = this.entity.paths && this.entity.paths.length > 0 ? this.entity.paths[0].duration : 0;
                        if (this.entity.duration && this.frameRate){
                            this.entity.durationTc = playerUtil.l100Ns2Tc(this.entity.duration, this.entity.type, this.frameRate);
                        }
                        this.mediaStore.initKeyframeDict(this.entity.fileGroups);
                        resolve(this.entity);
                    }
                    else {
                        reject();
                    }
                    this.isReqInitData = false;
                });
            }, (res) => {
                runInAction(() => {
                    if (res.data.error && res.data.error.title && res.data.error.code){
                        this.loadErrorCode = res.data.error.code;
                        if (res.data.error.code === 'B1066') {
                            this.loadError = '系统未定义该类型，无法浏览。';
                        } else {
                            this.loadError = res.data.error.title;
                        }
                        this.isReqInitData = false;
                    }
                    reject();
                });
            })
        });
    }

    @action
    public setEntityData(entityData: IFormItem[]){
        this.entity.entitydata = entityData;
    }

    @action
    public changeTopTab(name: string) {
        this.selectedTab.top = name;
    }

    @action
    public changeBottomTab(name: string) {
        this.selectedTab.bottom = name;
    }

    @action
    public setMetaEditable(editable: boolean) {
        this.metaEditable = editable;
    }

    @action
    public setPartMetaEditable(editable: boolean){
        this.partMetaEditable = editable;
    }

    @action
    public updateEntityTagedata(tagedata: IFormItem[]) {
        this.entity.tagedata = tagedata;
    }

    @action
    public setSelectedTabObj(obj: entityTypes.ISelectedTabObj ){
        this.selectedTabObj = obj;
    }

    public setPlayerPosition = (item: entityTypes.ICut) => {
        this.viewer.player.setCurrentTime(playerUtil.frame2Second(item.in, this.frameRate));
        this.viewer.player.setTrimin(playerUtil.frame2Second(item.in, this.frameRate));
        this.viewer.player.setTrimout(playerUtil.frame2Second(item.out, this.frameRate));
    }

    /** 能否使用历史 */
    public canUseHistory() {
        if (!_.get(this.stores.configStore, 'config.entity.history.enable', false)) {
            return false;
        }
        if (permissionUtil.judge('Default_ActionLogView')) {
            return true;
        }
        if (this.stores.configStore.config.entity.history.allowImportUser) {
            return (_.get(this.stores.entityStore.entity, 'importUser.userCode')
                === (this.stores.userStore.currentUser && this.stores.userStore.currentUser.userCode));
        }
        return false;
    }

    @action
    public setShowRegeneratePreviewFileBtn(show: boolean){
        this.showRegeneratePreviewFileBtn = show;
    }

    @action
    public getRelatedTopics(contentId: string) {
        return new Promise((resolve, reject)=>{
            entityApi.getRelatedTopics(contentId).then((res)=>{
                runInAction(()=>{
                    if(res.data.data && res.data.success){
                        this.relatedTopicsData = res.data.data
                    }
                })
            }).catch(res=>{
                runInAction(()=>{
                    if(res.data.error && res.data.error.title){
                        message.error(res.data.error.title)
                    }
                })
                
            })
        })

    }

    public openDetail(contentId: string){
        // 安卓
        if ((window as any).androidObj){
            (window as any).androidObj.openNewPage(window.location.origin + `/app/entity/${contentId}`)
        }
        // IOS
        else if ((window as any).webkit 
            && (window as any).webkit.messageHandlers 
            && (window as any).webkit.messageHandlers.openNewPage
            && (window as any).webkit.messageHandlers.openNewPage.postMessage){
            (window as any).webkit.messageHandlers.openNewPage.postMessage(window.location.origin + `/app/entity/${contentId}`)
        }
        else {
            window.open(`/app/entity/${contentId}`);
        }
    }
}
